import moment from 'moment';
import * as endpoints from '../constants/endpoints';
import { onlyUnique } from './helperFunctions';

export function chooseAction(value) {
  switch (value.toUpperCase()) {
    case 'A': return 'Angesehen';
    case 'B': return 'Bearbeitet';
    case 'C': return 'Formular erstellt';
    default: return '';
  }
};

export function chooseActivityType(activityType) {
  if (!activityType) return '';

  switch (activityType) {
    case 1: return 'Rückruf durch LO erbeten';
    case 2: return 'Rückruf durch LO durchgeführt';
    case 3: return 'Betriebsbesuch erbeten';
    case 4: return 'Betriebsbesuch durchgeführt';
    case 5: return 'Ansprechperson nicht erreicht/neuer Versuch';
    case 6: return 'Ansprechperson falsch/Bearbeitung beendet';
    case 7: return 'Antrag Löschung nach DSGVO';
    case 8: return 'Wird bearbeitet durch';
    default: return '';
  }
};

export function chooseContactPersonPosition(position) {
  if (!position && typeof position !== 'string') return '';

  switch (position.toUpperCase()) {
    case 'F': return 'GeschäftsführerIn';
    case 'G': return 'GesellschafterIn';
    case 'P': return 'ProkuristIn';
    case 'V': return 'Vorstandsmitglied';
    case 'A': return 'Aufsichtsradmitglied';
    default: return '';
  }
};

export function chooseCounty(county) {
  if (!county) return '';

  switch (county.toUpperCase()) {
    case 'V': return 'Vorarlberg';
    case 'T': return 'Tirol';
    case 'N': return 'Niederösterreich';
    case 'S': return 'Salzburg';
    case 'B': return 'Burgenland';
    case 'O': return 'Oberösterreich';
    case 'M': return 'Steiermark';
    case 'K': return 'Kärnten';
    case 'W': return 'Wien';
    default: return '';
  }
};

export function chooseMemberType(type) {
  if (!type) return '';

  switch (type.toUpperCase()) {
    case 'O': return 'Ordentlich';
    case 'A': return 'Außer ordentlich';
    case 'E': return 'Ehrenmitglied';
    default: return '';
  }
};

export function chooseGender(type) {
  if (!type) return '';

  switch (type.toUpperCase()) {
    case 'M': return 'Männlich';
    case 'W': return 'Weiblich';
    case 'D': return 'Divers';
    default: return '';
  }
};

export function chooseHardGoal(type) {
  if (!type) return '';

  switch (type) {
    case 1: return 'Aufnahme Ruhende Gewerbeberechtigungen';
    case 2: return 'Wahlkartenanträge';
    case 3: return 'DivKandidaten für Wahlvorschlagers';
    case 4: return 'Unterstützer für Wahlvorschlag';
    case 5: return 'Mitgliederwerbung';
    default: return '';
  }
};

export function getDate(date) {
  return date ? moment((typeof date == 'string') ? date : date?.$d).format('DD.MM.YYYY') : '';
};

export function getPerson(person) {
  return person ? (person.Firstname + ' ' + person.Lastname) : '';
};

export function getAddressRegistration(addressRegistration) {
  return addressRegistration
    ?
    (`${addressRegistration.Zipcode} ${addressRegistration.City} ${addressRegistration.Street} ${addressRegistration.Number ?? ""}`)
    :
    '';
};

export function getStatus(status) {
  switch (status) {
    case 1: return 'Aktiv';
    case 0: return 'Inaktiv';
    case 2: return 'Ruhend';
    default: return '???';
  }
};

export function getBusinessRegistrationListOverview(company) {
  if (company.AddressRegistrations.length > 0) {
    var businessRegistrationMap = new Map();

    company.AddressRegistrations.map(addressRegistration => {
      addressRegistration.BusinessRegistrations.map(businessRegistration => {
        businessRegistrationMap.set(businessRegistration.ID, businessRegistration)
      });
    });

    const businessRegistration = Array.from(businessRegistrationMap.values())

    return ((businessRegistration?.length > 0) ?
      businessRegistration.map((element) => {
        if(element.BusinessSubcategory){
          return `${element.BusinessSubcategory.County}${element.BusinessSubcategory.Number}`
        }
        return `${element.Business.BusinessSubcategory.County}${element.Business.BusinessSubcategory.Number}`
      }).join(', ') : '---');
  }

  return '';
};

export function getBusinessRegistrationListOverviewMultiple(companies) {
  let businessRegistrationArray = [];

  for (let i = 0; i < companies.length; i++) {
    const company = companies[i];

    if (company.AddressRegistrations.length > 0) {
      var businessRegistrationMap = new Map();

      company.AddressRegistrations.map(addressRegistration => {
        addressRegistration.BusinessRegistrations.map(businessRegistration => {
          businessRegistrationMap.set(businessRegistration.ID, businessRegistration)
        });
      });

      const businessRegistration = Array.from(businessRegistrationMap.values().map(element => element.Business.BusinessSubcategory.Number))

      businessRegistrationArray = [...businessRegistration, ...businessRegistrationArray].filter(onlyUnique);
    }
  }

  /*return ((businessRegistration?.length > 0) ?
        businessRegistration.map((element) => {
          return `${element.Business.BusinessSubcategory.County}${element.Business.BusinessSubcategory.Number}`
        }).join(', ') : '---');*/


  return businessRegistrationArray.join(', ');
};

export function getBusinessRegistrationListText(businessRegistrationList) {
  var businessRegistrationMap = new Map();

  businessRegistrationList.map(o => {
    businessRegistrationMap.set(o.Business.BusinessSubcategory.Number, o.Business);
  });

  return Array.from(businessRegistrationMap.keys()).sort().join(', ');
};

export function getBoolean(value) {
  return (value !== undefined) ? (value ? 'Ja' : 'Nein') : '';
};

export function chooseEmployees(type) {
  switch (type) {
    case 0: return '0';
    case 1: return '1 - 25';
    case 26: return '26 - 500';
    case 500: return '500+';
    default: return '';
  }
};

export function getPhotoUrl(row) {
  var endpoint = '';

  if (row.WKO)
    endpoint = endpoints.getCompanyPhotoSrc;
  else if (row.Gender)
    endpoint = endpoints.getPersonPhotoSrc;
  else
    endpoint = endpoints.getProfilePhotoSrc;

  // console.warn(row.Photo ? endpoints.apiUrlEx + endpoint + '?id=' + row.ID + '&date=' + Date.now() : null);

  return row.Photo ? endpoints.apiUrlEx + endpoint + '?id=' + row.ID + '&date=' + Date.now() : null
};
