import { useEffect, useState } from "react"
import { FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material"
import { useGlobalStyles } from "../../../customStyles"
import { IUser } from "../../../interfaces/IUser"
import ClearIcon from '@mui/icons-material/Clear';

export type UserFilters = {
    username?: string
    email?: string
    fullName?: string
    role?: string
    county?: string
    isAdmin?: boolean
    isActive?: boolean
}

type UserListFilterProps = {
    onChange: (filters: UserFilters) => void
}

export default function UserListFilter({ onChange }: UserListFilterProps) {

    const cssGlobalClass = useGlobalStyles();

    const [username, setUsername] = useState<UserFilters["username"]>();

    const [email, setEmail] = useState<UserFilters["email"]>();

    const [fullName, setFullname] = useState<UserFilters["fullName"]>();

    const [county, setCounty] = useState<UserFilters["county"]>("");

    useEffect(() => {
        onChange({
            username,
            email,
            fullName,
            county,
        })

    }, [username, email, fullName, county])

    const clearFilters = () => {
        setUsername("");
        setEmail("");
        setFullname("");
        setCounty("");
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "center", alignItems: "center" }}>
            <Input
                value={username}
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                className={cssGlobalClass.customInput}
                placeholder="Benutzername"
                sx={{ width: "25%", height: "60px" }}
            />

            <Input
                value={email}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className={cssGlobalClass.customInput}
                placeholder="Email"
                sx={{ width: "25%", height: "60px" }}
            />

            <Input
                value={fullName}
                type="text"
                onChange={(e) => setFullname(e.target.value)}
                className={cssGlobalClass.customInput}
                placeholder="Name"
                sx={{ width: "25%", height: "60px" }}
            />
            <FormControl sx={{ width: "25%", height: "56px" }}>
                <InputLabel id="county-label">Bundesland</InputLabel>
                <Select
                    labelId="county-label"
                    className={cssGlobalClass.customInput}
                    value={county}
                    onChange={(e) => setCounty(e.target.value as string)}
                    sx={{ height: "60px" }}
                >
                    <MenuItem value={""}>-</MenuItem>
                    <MenuItem value={'B'}>Burgenland</MenuItem>
                    <MenuItem value={'K'}>Kärnten</MenuItem>
                    <MenuItem value={'N'}>Niederösterreich</MenuItem>
                    <MenuItem value={'O'}>Oberösterreich</MenuItem>
                    <MenuItem value={'S'}>Salzburg</MenuItem>
                    <MenuItem value={'M'}>Steiermark</MenuItem>
                    <MenuItem value={'T'}>Tirol</MenuItem>
                    <MenuItem value={'V'}>Vorarlberg</MenuItem>
                    <MenuItem value={'W'}>Wien</MenuItem>
                </Select>
            </FormControl>
            <div style={{ height: "68px", display: "flex", alignItems: "center" }}>
                <ClearIcon sx={{ cursor: "pointer", height: "32px", width: "32px" }} onClick={() => clearFilters()} />
            </div>
        </div>
    )
}

export function filterUserList(userList: IUser[], setFilteredUserList: React.Dispatch<React.SetStateAction<IUser[]>>, filters: UserFilters) {
    let filteredUserList = userList;

    if (filters.username) {
        filteredUserList = filteredUserList.filter((user) => user.Username.toLowerCase().includes(filters.username!.toLowerCase()));
    }

    if (filters.email) {
        filteredUserList = filteredUserList.filter((user) => user.Email.toLowerCase().includes(filters.email!.toLowerCase()));
    }

    if (filters.fullName) {

        const searchWords = filters.fullName!.toLowerCase().split(" ");
        filteredUserList = filteredUserList.filter((user) => searchWords.every(word => getFullName(user).toLowerCase().includes(word)));
    }

    if (filters.county) {
        filteredUserList = filteredUserList.filter((user) => user.County === filters.county);
    }

    setFilteredUserList(filteredUserList);
}

function getFullName(user: IUser) {
    return user.Firstname + " " + user.Lastname;
}