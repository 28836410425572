import { useSnackbar } from "notistack";
import { useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation } from "./UserService"
import { IUser } from "../../../interfaces/IUser";
import { RTKQueryErrorHandler } from "../../utils";
import Swal from "sweetalert2";

export const useHandleCreateUser = () => {
    const [createUser, { isLoading }] = useCreateUserMutation();

    const { enqueueSnackbar } = useSnackbar();

    const handleCreateUser = async (user: IUser) => {
        try {
            const result = await createUser(user).unwrap();
            enqueueSnackbar("Benutzer erfolgreich erstellt", { variant: "success" });
        }
        catch (err) {
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleCreateUser, isLoading };
}

export const useHandleUpdateUser = () => {
    const [updateUser, { isLoading }] = useUpdateUserMutation();

    const { enqueueSnackbar } = useSnackbar();

    const handleUpdateUser = async (user: IUser) => {
        try {
            const result = await updateUser(user).unwrap();
            console.log("handleUpdateUser result", result);
            enqueueSnackbar("Benutzer erfolgreich bearbeitet", { variant: "success" });
        }
        catch (err) {
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleUpdateUser, isLoading }
}

export const useHandleDeleteUser = () => {
    const [deleteUser, { isLoading }] = useDeleteUserMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleDeleteUser = async (id: number) => {
        try {
            const isConfirmed = await confirmDeletePopup();
            if (!isConfirmed) return;
            const result = await deleteUser(id).unwrap();
            enqueueSnackbar("Benutzer erfolgreich gelöscht", { variant: "success" });
        }
        catch (err) {
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleDeleteUser, isLoading }
}

const confirmDeletePopup = () => {
    return Swal.fire({
        title: "Datensatz löschen",
        text: "Kann nicht rückgängig gemacht werden",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Abbrechen",
        confirmButtonText: "Löschen",
        confirmButtonColor: "#d62932",
    }).then((result) => {
        return result.isConfirmed;
    });
};