import React from 'react';
import { useEffect, useContext, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { FormControl, Button, Grid, Box, Tooltip, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import ModalWrapper from '../../common-components/ModalWrapper';
import CountySelect from '../../common-components/CountySelect';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import FormBooleanSelect from '../../common-components/FormBooleanSelect';
import { UserContext } from '../../pages/User/UserContext';
import { useStyles } from './styles';
import UserRoleSelect from '../../common-components/UserRoleSelect/UserRoleSelect';
import SaveButton from '../../common-components/SaveButton/SaveButton';

export default function EditUserPopUp({
  isOpen,
  onClose,
  onInviteClicked,
  onSaveClicked,
  onManagePermissionClicked,
  loading
}) {
  const classes = useStyles();
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);

  const { currentUser } = useContext(UserContext);

  const { control, reset } = useForm({
    defaultValues: {
      ID: '',
      Username: '',
      Email: '',
      Mobile: '',
      Firstname: '',
      Lastname: '',
      IsAdmin: false,
      County: null,
      IsActive: true,
      Login: null,
      Role: '',
      SendingRestriction: false
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset(currentUser);
    } else {
      const defaultValues = {
        ID: '',
        Username: '',
        Email: '',
        Mobile: '',
        Firstname: '',
        Lastname: '',
        IsAdmin: false,
        County: null,
        IsActive: true,
        Login: null,
        UserRole: '',
        SendingRestriction: false
      };

      reset(defaultValues);
    }
  }, [isOpen]);

  const values = useWatch({ control });

  const disabled =
    emailError ||
    mobileError ||
    !values?.Username ||
    !values?.Email ||
    !values?.Mobile ||
    !values?.Firstname ||
    !values?.Lastname ||
    values?.IsAdmin === '' ||
    values?.IsActive === '';

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Benutzer bearbeiten">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl>
          <FormTextField
            name="Username"
            label="Benutzername"
            control={control}
            required
          />
          <FormTextField
            name="Email"
            label="Email"
            control={control}
            required
            type="email"
            func={setEmailError}
          />
          <FormTextField
            name="Mobile"
            label="Mobil"
            control={control}
            required
            type="mobile"
            func={setMobileError}
          />
          <FormTextField
            name="Firstname"
            label="Vorname"
            control={control}
            required
          />
          <FormTextField
            name="Lastname"
            label="Nachname"
            control={control}
            required
          />
          <FormBooleanSelect
            label="Admin"
            name="IsAdmin"
            control={control}
            value={values?.IsAdmin}
            required
          />
          <CountySelect
            name="County"
            control={control}
            value={values?.County || ''}
          />
          <FormBooleanSelect
            label="Aktiv"
            name="IsActive"
            control={control}
            value={values?.IsActive}
            required
          />
          <FormBooleanSelect
            label="Sendeeinschränkung"
            name="SendingRestriction"
            control={control}
            value={values?.SendingRestriction}
            required
          />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={9}>
              <UserRoleSelect
                control={control}
                value={values?.UserRole || ''}
                fullWidth
                sx={{ marginRight: "12px" }}
              />
            </Grid>
            <Grid item xs={3}>
              <Tooltip title={(values.UserRole !== "F") ? "Berechtigungen können nur für Funktionäre verwaltet werden" : ""}>
                <span><Button disabled={values.UserRole !== "F"} sx={{ width: "100%", height: "40px" }} onClick={() => onManagePermissionClicked()} >Berechtigungen verwalten</Button></span>
              </Tooltip>
            </Grid>
          </Grid>
        </FormControl>
        <BoxCenter>
          {values.ID && (
            <Button
              onClick={() => onInviteClicked(values)}
            >
              Einladen
            </Button>
          )}
          {/*           <Button
            sx={{ maxHeight: "50px", minWidth: "150px" }}
            onClick={() => onSaveClicked(values)}
            disabled={disabled || loading}
          >
            {loading ? <CircularProgress size={"32px"}/> : <span>Speichern</span>}
          </Button> */}
          <SaveButton
            disabled={disabled}
            loading={loading}
            onClick={() => onSaveClicked(values)}
          />
        </BoxCenter>
      </LocalizationProvider>
    </ModalWrapper>
  );
}