import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../basequery";
import * as endpoints from "../../constants/endpoints";

export const BusinessAPI = createApi({
    reducerPath: 'Business',
    baseQuery,
    tagTypes: ["Business", "BusinessList"],
    endpoints: (build) => ({
        getList: build.query({
            query: (subcategoryId) => ({
                url: endpoints.getBusinessList,
                body: { subcategoryId },
                method: 'POST',
            }),
            providesTags: () => ['BusinessList']
        })
    })
});
