import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Input,
} from '@mui/material';

import { PopupModes } from '../../constants';
import { useGlobalStyles } from '../../../../../customStyles';
import BoxCenter from '../../../../common-components/BoxCenter';
import {
  processVisitenkarte,
  optimizeImage,
} from '../../../../../helpers/tesseractUtils';
import { useForm, useWatch } from 'react-hook-form';
import FormTextField from '../../../../common-components/FormTextField';
import FormCheckbox from '../../../../common-components/FormCheckbox';
import { DecorativeCorners } from '../../../../common-components/DecorativeCorners';
import FormSelect from '../../../../common-components/FormSelect';
import MenuItem from '@mui/material/MenuItem';

import { styles } from './styles';

export const ImagePreview = ({
  base64Images,
  hiddenFileInputRef,
  onSave,
  mode,
  onRecapture,
  isScanMode = false,
  scannedData,
  setScannedData,
  showAdditionalInfoFields = false,
  selectedFiles,
  companyData,

}) => {
  const cssGlobalClass = useGlobalStyles();
  const [isScanInProgress, setIsScanInProgress] = useState(false);
  //const [isFamily, setIsFamily] = useState(false);


  const { control, reset } = useForm({
    defaultValues: {
      caption: '',
      sendMail: false,
      email: '',
      filename: '',
    },
  });

  const values = useWatch({ control });

  const onCheckboxClicked = () => {
    if (values.sendMail) {
      reset({ ...values, sendMail: !values.sendMail, email: '' });
    }
  };

  const openFileBrowser = () => {
    hiddenFileInputRef.current.click();
  };

  useEffect(() => {
    setScannedData(null);
  }, [base64Images]);

  const scanCard = () => {
    setIsScanInProgress(true);

    optimizeImage(base64Images[0]).then((downscaledImage) => {
      processVisitenkarte(downscaledImage).then((data) => {
        /*
         * foundData object properties
         * city:         string|undefined
         * email:        Array[string]|undefined
         * firstname:    string|undefined
         * housenumber:  number|undefined
         * lastname:     string|undefined
         * postalcode:   number|undefined
         * street:       string|undefined
         * telephone:    Array[string]|undefined
         */

        setScannedData(data);

        setIsScanInProgress(false);
      });
    });
  };

  const handleSave = () => {
    if (showAdditionalInfoFields) {
      const fd = new FormData();

      fd.append('companyId', companyData.ID);
      fd.append('caption', values.caption);
      fd.append('email', values.email);
      fd.append('file', selectedFiles[0]);
      fd.append('sendMail', values.sendMail)

      onSave(fd);
    } else {
      onSave(base64Images);
    }
  };

  const disabledEmail = !values.sendMail;


  const mappingLabels = {
    city: { label: 'Stadt' },
    email: { label: 'Email' },
    firstname: { label: 'Vorname' },
    lastname: { label: 'Nachname' },
    postalcode: { label: 'Postleitzahl' },
    street: { label: 'Straße' },
    telephone: { label: 'Telefon' },
    housenumber: { label: 'Hausnummer' },
  };

  const optionPhone = {
    TelephonePerson: 'Telefon',
    TelephonePrivatePerson: 'Telefon (privat)',
    MobilePerson: 'Mobil',
    MobilePrivatePerson: 'Mobil (privat)',
  };

  const optionMail = {
    EmailPerson: 'E-Mail',
    EmailEventPerson: 'E-Mail (Veranstaltungen)',
    EmailFinancialPerson: 'E-Mail (Finanzen)',
    EmailNewsletterPerson: 'E-Mail (Newsletter)',
  };



  return (
    <Box sx={styles.imagePreviewContainer}>
      <Box sx={styles.imageContainer}>
        <DecorativeCorners sx={styles.decorativeCorners}>
          {base64Images.map((src, index) => (
            <Box src={src} component="img" sx={styles.image} key={index} />
          ))}
        </DecorativeCorners>
      </Box>
      {showAdditionalInfoFields && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 5,
            gap: 2,
          }}
        >
          <FormTextField
            name="caption"
            label="Bezeichnung"
            control={control}
            fullWidth
            required
          />
          <FormCheckbox
            name="sendMail"
            control={control}
            label="Kopie an folgende Email Adresse:"
            onClick={onCheckboxClicked}
            sx={{ alignSelf: 'start' }}
          />
          <FormTextField
            name="email"
            label="Email"
            control={control}
            fullWidth
            required
            disabled={disabledEmail}
          />
        </Box>
      )}
      <Box sx={styles.buttonWrapper}>
        {mode === PopupModes.FILE_SELECTION ? (
          <Button
            variant="outlined"
            onClick={openFileBrowser}
            sx={styles.secondaryActionButton}
          >
            Andere Datei
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={onRecapture}
            sx={styles.secondaryActionButton}
          >
            Neues Foto
          </Button>
        )}
        {!isScanMode && (
          <Button onClick={handleSave} sx={styles.openFileBrowserButton} disabled={!values.caption && companyData}>
            Speichern
          </Button>
        )}
      </Box>
      {isScanMode && (
        <Box>
          {scannedData !== null &&
            (Object.keys(scannedData).length !== 0 ? (
              <BoxCenter>
                <Grid container spacing={2} >
                  {Object.entries(scannedData).map(function (entry) {
                    return (
                      <React.Fragment key={entry[0]}>
                        {Array.isArray(entry[1]) ? (
                          entry[1].map(function (item, index) {
                            return (
                              <React.Fragment  key={`${entry[0]}-${index}`}>
                                <Grid item xs={12} md={4} key={`${entry[0]}-${index}`}>
                                  <Item>
                                    <FormSelect
                                      fullWidth={true}
                                      label={mappingLabels[entry[0]].label}
                                      name={`${entry[0]}-${index}`}
                                      onChange={(e) => {
                                        scannedData[entry[0]][index] = {
                                          type: e.target.value, value:
                                            (typeof scannedData[entry[0]][index] === "string" ? item : scannedData[entry[0]][index].value)
                                        }
                                      }
                                      }
                                      value={entry[0] === 'telephone' ? optionPhone[0] : optionMail[0]}
                                    >
                                      {Object.keys(entry[0] === 'telephone' ? optionPhone : optionMail).map((key) => (
                                        <MenuItem key={key} value={key}>
                                          {entry[0] === 'telephone' ? optionPhone[key] : optionMail[key]}
                                        </MenuItem>
                                      ))}
                                    </FormSelect>
                                  </Item>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <Item>
                                    <Input
                                      fullWidth={true}
                                      className={cssGlobalClass.customInput}
                                      defaultValue={item}
                                      name={`${entry[0]}-${index}`}
                                      onChange={(e) => {
                                        scannedData[entry[0]][index] = { value: e.target.value, type: (scannedData[entry[0]][index] === "string" ? (entry[0] === 'telephone' ? optionPhone[0] : optionMail[0]) : scannedData[entry[0]][index].type) }
                                      }}
                                    />
                                  </Item>
                                </Grid>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <>
                            <Grid item xs={12} md={4} key={entry[0]}>
                              <Item >{mappingLabels[entry[0]].label}</Item>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Item ><Input
                                fullWidth={true}
                                className={cssGlobalClass.customInput}
                                defaultValue={entry[1]}

                                onChange={(e) => {
                                  scannedData = { ...scannedData, [entry[0]]: e.target.value }
                                }}
                              />

                              </Item>
                            </Grid>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {/** 
                  <Grid item xs={12} md={8}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isFamily}
                            onChange={(event) => setIsFamily(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label='SWV Familie'
                        className={cssGlobalClass.customCheckbox}
                      />
                    </Box>

                  </Grid>
                */}
                </Grid>
              </BoxCenter>
            ) : (
              <BoxCenter mt={8}>
                <Typography>
                  Couldn&apos;t read data from the selected image.
                </Typography>
              </BoxCenter>
            ))}
          <BoxCenter sx={{ flexDirection: 'column !important', alignItems: 'center ' }}>
            {isScanInProgress && (
              <BoxCenter sx={{ height: '50', mb: '30px' }}>
                <CircularProgress variant="indeterminate" />
              </BoxCenter>
            )}
            {scannedData ? (
              <Button
                onClick={() => onSave( scannedData )}
                sx={styles.openFileBrowserButton}
                disabled={!scannedData.firstname || !scannedData.lastname}
              >
                Speichern
              </Button>
            ) : (


              <Button onClick={scanCard} sx={styles.openFileBrowserButton}>
                Text erkennen
              </Button>

            )}
          </BoxCenter>

        </Box>
      )}
    </Box>
  );
};

ImagePreview.propTypes = {
  hiddenFileInputRef: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onRecapture: PropTypes.func.isRequired,
  base64Images: PropTypes.arrayOf(PropTypes.string).isRequired,
  mode: PropTypes.oneOf([PopupModes.WEBCAM, PopupModes.FILE_SELECTION])
    .isRequired,
  isScanMode: PropTypes.bool,
  showAdditionalInfoFields: PropTypes.bool,
};
