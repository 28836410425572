import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { AUTH_TOKEN_KEY } from '../constants/appConstants';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { goTo } from '../router/routerHistory';
import { refreshToken } from '../services/apiClient';

export const baseQueryWithoutReauth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BE_URL,
  prepareHeaders: async (headers) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    // If we have a token set in localstorage, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQuery = async (args, api, extraOptions) => {
  let result = await baseQueryWithoutReauth(args, api, extraOptions);
  console.log('Result in baseQuery');

  console.log('Result in baseQuery', result, result.meta?.response?.status);
  console.log(result.meta?.response?.status);

  // If a 403 error occurs, attempt to refresh the token
  if (result.meta?.response?.status === 403) {
    const originalRequest = args;
    try {
      // Refresh the token
      const newTokens = await refreshToken();

      if (newTokens?.accessToken) {
        // Store new access token
        localStorage.setItem(AUTH_TOKEN_KEY, newTokens.accessToken);
        
        result = await baseQueryWithoutReauth(
          originalRequest,
          api,
          extraOptions,
        );
      } else {
        // Handle cases where the refresh token process fails
        console.log('Failed to refresh token');
        // Optionally: logout or redirect user
      }
    } catch (error) {
      console.log('Error while refreshing token', error);
      // Handle token refresh error (e.g., redirect to login page)
    }
  }

  return result;
};
