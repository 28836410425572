import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import CustomFormSelect from "../../../common-components/CustomFormSelect/CustomFormSelect";
import { useGlobalStyles } from "../../../../customStyles";
import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { UserContext } from "../../../pages/User/UserContext";
import { useGetAllDistrictsQuery } from "../../../../redux/services/DistrictService";
import { IDistrict } from "../../../../interfaces/IDistrict";
import { useGetPermittedBusinessSubcategoriesForUserQuery, useGetBusinessSubcategoryPermittedDistrictsByUserIdQuery, useGetBusinessSubcategoryUserPermissionsByUserIdQuery, UserPermissionBusinessSubcategory } from "../../../../redux/services/UserPermissions/UserPermissionsService";
import { IBusinessSubcategory } from "../../../../interfaces/IBusinessSubcategory";
import { useHandleInsertBusinessSubcategoryPermissionsInDistrict } from "../../../../redux/services/UserPermissions/UserPermissionsRequestHandlers";
import SaveButton from "../../../common-components/SaveButton/SaveButton";

interface IPermittedSubcategories extends IBusinessSubcategory {
    CountyAndNumber?: string
}

export default function BusinessSubcategoryUserPermissionsDistrictOverviewTable() {

    const cssClass = useGlobalStyles();

    const { currentUser } = useContext(UserContext);

    const { control, reset, setValue } = useForm({
        defaultValues: {
            CountyAndNumber: ''
        }
    })
    const values = useWatch({ control })

    const { data: districts } = useGetAllDistrictsQuery();
    const { data: permittedDistricts } = useGetBusinessSubcategoryPermittedDistrictsByUserIdQuery(currentUser ? currentUser.ID : 0);
    const { data: userPermissionsForBusinessSubcategories } = useGetBusinessSubcategoryUserPermissionsByUserIdQuery(currentUser ? currentUser.ID : 0);

    const { data: permittedBusinessSubcategories } = useGetPermittedBusinessSubcategoriesForUserQuery(currentUser ? currentUser.ID : 0);

    const { handleInsertBusinessSubcategoryPermissionsInDistrict, isLoading: isInsertPermissionsLoading } = useHandleInsertBusinessSubcategoryPermissionsInDistrict();

    const [filteredDistricts, setFilteredDistricts] = useState<IDistrict[]>([]);
    const [selectedDistrictsForUser, setSelectedDistrictsForUser] = useState<number[]>([]);
    const [permittedBusinessSubcategoriesForUser, setPermittedBusinessSubcategoriesForUser] = useState<IPermittedSubcategories[]>([]);


    useEffect(() => {
        setValue("CountyAndNumber", permittedBusinessSubcategoriesForUser.length > 0 ? permittedBusinessSubcategoriesForUser[0].CountyAndNumber ?? "" : "");
    }, [permittedBusinessSubcategoriesForUser])

    useEffect(() => {
        setFilteredDistricts(districts ? districts.filter(district => district.County === values.CountyAndNumber?.charAt(0)) : []);
    }, [values.CountyAndNumber, districts])

    useEffect(() => {
        reset({ CountyAndNumber: '' })
    }, [currentUser])

    useEffect(() => {
        setSelectedDistrictsForUser(getSelectedDistrictsForCurrentBusinessSubcategory())
    }, [permittedDistricts, values.CountyAndNumber])

    useEffect(() => {
        setPermittedBusinessSubcategoriesForUser(permittedBusinessSubcategories ? permittedBusinessSubcategories.map((subcategory) => ({ ...subcategory, CountyAndNumber: `${subcategory.County}${subcategory.Number}` })) : []);
    }, [permittedBusinessSubcategories])

    const handleCheckboxChange = (id: number) => {
        const index = selectedDistrictsForUser.indexOf(id);

        if (index === -1) {
            setSelectedDistrictsForUser((old) => [...old, id]);
        }
        else {
            const newArr = [...selectedDistrictsForUser];
            newArr.splice(index, 1);
            setSelectedDistrictsForUser(newArr);
        }
    }

    const checkboxIsChecked = (id: number) => {
        return selectedDistrictsForUser.includes(id);
    }

    const handleSave = async () => {
        //Send current userId, selected district id + permitted business subcategory?

        const selectedSubcategory = getCurrentlySelectedBusinessSubcategory();

        if (selectedSubcategory === undefined) return;

        const userPermissionBusinessSubcategory = getUserPermissionForCurrentlySelectedBusinessSubcategory(selectedSubcategory.ID);

        if (userPermissionBusinessSubcategory === undefined) return;

        const data = {
            UserId: currentUser.ID,
            DistrictIds: [...selectedDistrictsForUser],
            BusinessSubcategoryId: selectedSubcategory.ID,
        }

        console.log("data", data);
        //TODO: implement save for districts
        await handleInsertBusinessSubcategoryPermissionsInDistrict(data);
    }

    return (
        <>
            <CustomFormSelect name="CountyAndNumber" label="Fachgruppe" valueKey="CountyAndNumber" titleKey="CountyAndNumber" options={permittedBusinessSubcategoriesForUser} control={control} />
            <Table stickyHeader>
                <TableHead>
                    <TableRow className={cssClass.defaultCursor}>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Bezirksnummer</TableCell>
                        <TableCell>Kennzeichen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredDistricts.map((district, index) => (
                        <TableRow
                            key={index}
                            onClick={() => handleCheckboxChange(district.ID)}
                        >
                            <TableCell style={{ textAlign: "center" }}><input className={cssClass.checkboxScaled} type='checkbox' onChange={() => handleCheckboxChange(district.ID)} checked={checkboxIsChecked(district.ID)} /></TableCell>
                            <TableCell>{district.Name}</TableCell>
                            <TableCell>{district.Number}</TableCell>
                            <TableCell>{district.Licenseplate}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter style={{ position: 'sticky', bottom: 0, width: "100%" }}>
                    <TableRow className={cssClass.defaultCursor} style={{ backgroundColor: '#f5f5f5' }} >
                        <TableCell colSpan={11}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <SaveButton
                                    disabled={values.CountyAndNumber === "" || isInsertPermissionsLoading}
                                    loading={isInsertPermissionsLoading}
                                    onClick={handleSave}
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    )


    function getSelectedDistrictsForCurrentBusinessSubcategory() {
        if (!districts || districts.length === 0 || !permittedDistricts || permittedDistricts.length === 0 || values.CountyAndNumber === "") {
            return []
        }

        const selectedBusinessSubcategoryNumber = values.CountyAndNumber?.slice(1);
        const selectedBusinessSubcategoryId = permittedBusinessSubcategories?.find(permittedBusinessSubcategory =>
            permittedBusinessSubcategory.Number === selectedBusinessSubcategoryNumber
        )?.ID;

        const selectedCounty = values.CountyAndNumber?.charAt(0);

        const permittedDistrictIds = permittedDistricts.map(({ DistrictId, BusinessSubcategoryId }) => ({ BusinessSubcategoryId, DistrictId }));

        let permittedDistrictsForCurrentBusinessSubcategory = districts.filter(district => district.County === selectedCounty && permittedDistrictIds.find(permittedDistrict => permittedDistrict.DistrictId === district.ID && permittedDistrict.BusinessSubcategoryId === selectedBusinessSubcategoryId));

        return permittedDistrictsForCurrentBusinessSubcategory.map(entry => entry.ID);
    }

    function getCurrentlySelectedBusinessSubcategory() {
        const county = values.CountyAndNumber?.charAt(0);
        const number = values.CountyAndNumber?.substring(1, values.CountyAndNumber.length);
        const selectedBusinessSubcategory = permittedBusinessSubcategories?.find((item) => item.County === county && item.Number === number);
        return selectedBusinessSubcategory;
    }

    function getUserPermissionForCurrentlySelectedBusinessSubcategory(selectedSubcategoryId: number) {
        const userPermission = userPermissionsForBusinessSubcategories?.find((item) => item.BusinessSubcategoryId === selectedSubcategoryId);

        console.log("userPermission", userPermission);

        return userPermission;
    }
}