import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../basequery";
import * as endpoints from '../../constants/endpoints';


export const CommunicationDataApi = createApi({
    reducerPath: "CommunicationDataApi",
    baseQuery,
    tagTypes: ["CommunicationData"],
    endpoints: (builder) => ({
        getCommunicationData: builder.query({
            query: (id: number) => ({
                url: endpoints.getCommunicationData,
                params: { id },
                method: "GET",
            }),
            providesTags: (result, error, id) => [{ type: "CommunicationData", id: id }]
        })
    })
})

export const { useGetCommunicationDataQuery } = CommunicationDataApi