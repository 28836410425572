import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Box from '@mui/material/Box';
import { Avatar, FormControl, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { requestMediaPermissions } from 'mic-check';
import { CameraAltOutlined, PhotoSizeSelectActualOutlined, UploadFile } from '@mui/icons-material';
import { testImageUpload } from '../../../helpers/validateEmail';
import { useSnackbarHelpers } from '../../../helpers/utils';
import { Messages } from '../../../constants/messages';
import * as FileService from '../../../services/requests/file';

import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import FormCheckbox from '../../common-components/FormCheckbox';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

import * as endpoints from '../../../constants/endpoints';
import CustomButton from '../../common-components/CustomButton';

export default function PhotoPopUp({ isOpen, onClose, PhotoContext }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const hiddenFileInput = React.useRef(null);
  const { showErrorSnackbar } = useSnackbarHelpers();

  const { chosenPhoto, companyData } = useContext(PhotoContext);

  const [photoURL, setPhotoURL] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [file, setFile] = useState(null);

  const { control, reset } = useForm({
    defaultValues: {
      caption: '',
      sendMail: false,
      email: '',
      filename: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      if (chosenPhoto?.ID) {
        const defaultValues = {
          caption: chosenPhoto.Caption,
          sendMail: chosenPhoto.Email != '',
          email: chosenPhoto.Email,
          filename: chosenPhoto.Originalname,
        };

        reset(defaultValues);

        setPhotoURL(endpoints.apiUrlEx + endpoints.getPhotoSrc + '?id=' + chosenPhoto.ID + '&date=' + Date.now());
      }
    } else {
      const defaultValues = {
        caption: '',
        sendMail: false,
        email: '',
        filename: '',
      };

      reset(defaultValues);

      setPhotoURL(null);
    }

    setPhoto(null);
    setFile(null);
  }, [isOpen]);

  const onLibraryClicked = (_) => {
    hiddenFileInput.current.click();
  };

  const onCameraClicked = async () => {
    await requestMediaPermissions({ audio: false, video: true });
  };

  const onCheckboxClicked = () => {
    if (values.sendMail) {
      reset({ ...values, sendMail: !values.sendMail, email: '' });
    }
  };

  const onFileChanged = (event) => {
    const file = event.target.files[0];

    if (!testImageUpload(file)) {
      showErrorSnackbar(Messages.ErrorUpload);
    } else {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = async function () {
        reset({ ...values, filename: file.name, upload: reader.result });
        setPhoto(reader.result);
        setFile(file);
      };
    }
  };

  const onSaveClicked = async () => {
    const fd = new FormData();
    fd.append('companyId', companyData.ID);
    fd.append('caption', values.caption);
    fd.append('email', values.email);
    fd.append('file', file);
    fd.append('sendMail', values.sendMail)

    await FileService.insertOne(fd, onClose);
  };

  const disabled = !values.caption || (!file && !chosenPhoto) || (values.sendMail && !values.email);
  const disabledEmail = !values.sendMail;

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Foto" className={cssClass.swvModalResponsiveMods + ' ' + classes.wrapper}>
      <FormControl>
        <Grid container columnSpacing={{ xs: 5 }} rowSpacing={{ xs: 5, md: 0 }}>
          <Grid item xs={12} md={6}>
            <BoxCenter sx={{ alignItems: 'center', gap: '16px' }}>
              <CameraAltOutlined sx={{ fontSize: '50px' }} />
              <PhotoSizeSelectActualOutlined sx={{ fontSize: '46px' }} />
            </BoxCenter>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
              <Typography fontSize={24} fontWeight={500} mb={2}>Bild hochladen</Typography>
              <Typography fontSize={18} mb={2}>Drag & Drop</Typography>
              <Typography fontSize={16}>oder</Typography>
            </Box>
            <BoxCenter>
              <CustomButton value="Kamera" onClick={onCameraClicked} />
              <CustomButton value="Bibliothek" onClick={onLibraryClicked} />
            </BoxCenter>
          </Grid>
          <Grid item xs={12} md={6}>
            {chosenPhoto || photo ? (
              <BoxCenter>
                <img style={{ width: 'auto', height: 120, borderRadius: 4 }} src={photoURL || photo} />
              </BoxCenter>
            ) : (
              <BoxCenter>
                <Avatar
                  sx={{
                    m: 1,
                    bgcolor: 'grey',
                    width: 90,
                    height: 90,
                  }}
                >
                  <UploadFile sx={{ fontSize: 54 }} />
                </Avatar>
              </BoxCenter>
            )}

            <Box className={cssClass.formFlexRow}>
              <BoxCenter>{values.filename}</BoxCenter>
              <Box sx={{ mt: 3 }}>
                <FormTextField name="caption" label="Bezeichnung" control={control} fullWidth required />
                <FormCheckbox name="sendMail" control={control} label="Kopie an folgende Email Adresse:" onClick={onCheckboxClicked} />
                <FormTextField name="email" label="Email" control={control} fullWidth required disabled={disabledEmail} />
                <BoxCenter>
                  <CustomButton value="Speichern" onClick={onSaveClicked} disabled={disabled} />
                </BoxCenter>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <input accept="image/*" type="file" ref={hiddenFileInput} onChange={onFileChanged} style={{ display: 'none' }} />
      </FormControl>
    </ModalWrapper>
  );
}
