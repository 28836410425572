import React, { useState } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setJWTInfo, setUserData } from '../../../redux/slices/appSlice';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import * as AuthenticationService from '../../../services/requests/authentication';
import { Messages } from '../../../constants/messages';
import { useSnackbarHelpers } from '../../../helpers/utils';
import BoxCenter from '../../common-components/BoxCenter';
import FormLink from '../../common-components/FormLink';
import FormTextField from '../../common-components/FormTextField';
import LogoLarge from '../../common-components/LogoLarge';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';

export default function SecondFactorLogin() {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const navigate = useNavigate();
  //const [cookie, setCookie] = useCookies(['refreshToken']);
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showInfoSnackbar, showErrorSnackbar } = useSnackbarHelpers();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      Code: '',
    },
  });

  const values = useWatch({ control });

  function base64UrlToBase64(base64Url) {
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    while (base64.length % 4) {
      base64 += '=';
    }
    return base64;
  }

  function jwtDecode(token) {
    let decodedToken = {};
    decodedToken.raw = token;
    decodedToken.header = JSON.parse(window.atob(base64UrlToBase64(token.split('.')[0])));
    decodedToken.payload = JSON.parse(window.atob(base64UrlToBase64(token.split('.')[1])));
    return decodedToken;
  }

  async function onLoginClicked() {
    setIsSubmitting(true)
    await AuthenticationService.submitTFA(
      { ID: localStorage.getItem('ID'), smscode: values.Code },
      (data, result) => {
        dispatch(setUserData(data));
        dispatch(setJWTInfo(jwtDecode(result.Token)))
        //setCookie('refreshToken', result.TokenRefresh);
        localStorage.setItem('AuthToken', result.Token);
        localStorage.setItem('userData', JSON.stringify(data));
        navigate('/', { replace: false });
        setIsSubmitting(false)
      },
      () => {
        showErrorSnackbar(Messages.ErrorParameters);
        setIsSubmitting(false)
      },
    );
  }

  async function onResendClicked() {
    const result = await AuthenticationService.resendTFA(localStorage.getItem('email'));
    if (result.code === 401) {
      navigate('/login')
    }
  }

  const disabled = !values.Code;

  return (
    <Box className={cssClass.gradientBackground}>
      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'center',
          }}
        >
          <LogoLarge />
          <form onSubmit={handleSubmit(onLoginClicked)}>
            <FormTextField
              name="Code"
              label="SMS Code"
              control={control}
              type="number"
              required
              light
              autoFocus
            />
            <BoxCenter>
              <Button
                type='submit'
                size='large'
                disabled={disabled || isSubmitting}
                endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                className={[cssClass.buttonDark, cssClass.buttonLogin]}
              >
                Anmelden
              </Button>
            </BoxCenter>
            <BoxCenter>
              <FormLink
                onClick={() => onResendClicked()}
                sx={{ color: '#FFFFFF' }}
              >
                SMS nochmal schicken
              </FormLink>
            </BoxCenter>
          </form>
        </Box>
      </Container>
    </Box>
  );
}
