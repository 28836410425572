import { useSnackbar } from "notistack";
import { CreateGeneralUserPermissionsDistricts, CreateUserPermissionBusinessSubcategories, CreateUserPermissionsCounty, CreateUserPermissionsForBusinessSubcategoryInDistrict, useInsertBusinessSubcategoryUserPermissionsDistrictMutation, useInsertBusinessSubcategoryUserPermissionsMutation, useInsertGeneralUserPermissionsDistrictMutation, useInsertGeneralUserPermissionsMutation } from "./UserPermissionsService";
import { RTKQueryErrorHandler } from "../../utils";

export const useHandleInsertGeneralUserPermissions = () => {

    const [insertGeneralUserPermissions, { isLoading }] = useInsertGeneralUserPermissionsMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleInsertGeneralUserPermissions = async (permissions: CreateUserPermissionsCounty) => {
        try {
            const result = await insertGeneralUserPermissions(permissions).unwrap();
            enqueueSnackbar("Berechtigte Bundesländer erfolgreich bearbeitet", { variant: "success" })
        }
        catch (err) {
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleInsertGeneralUserPermissions, isLoading };
}

export const useHandleInsertGeneralDistrictPermissions = () => {
    const [insertDistrictPermissions, { isLoading }] = useInsertGeneralUserPermissionsDistrictMutation();

    const { enqueueSnackbar } = useSnackbar();

    const handleInsertGeneralDistrictPermissions = async (permissions: CreateGeneralUserPermissionsDistricts) => {
        try {
            const result = await insertDistrictPermissions(permissions).unwrap();
            enqueueSnackbar("Berechtigte Bezirke erfolgreich bearbeitet", { variant: "success" });
        }
        catch (err) {
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleInsertGeneralDistrictPermissions, isLoading }
}

export const useHandleInsertBusinessSubcategoryPermissionsInCounty = () => {
    const [insertBusinessSubcategoryPermissions, { isLoading }] = useInsertBusinessSubcategoryUserPermissionsMutation();

    const { enqueueSnackbar } = useSnackbar();

    const handleInsertBusinessSubcategoryPermissions = async (permissions: CreateUserPermissionBusinessSubcategories) => {
        try {
            const result = await insertBusinessSubcategoryPermissions(permissions).unwrap();
            enqueueSnackbar("Berechtigte Fachgruppen erfolgreich bearbeitet", { variant: "success" });
        }
        catch (err) {
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleInsertBusinessSubcategoryPermissions, isLoading };
}

export const useHandleInsertBusinessSubcategoryPermissionsInDistrict = () => {
    const [insertBusinessSubcategoryPermissionsInDistrict, { isLoading }] = useInsertBusinessSubcategoryUserPermissionsDistrictMutation();

    const { enqueueSnackbar } = useSnackbar();

    const handleInsertBusinessSubcategoryPermissionsInDistrict = async (permissions: CreateUserPermissionsForBusinessSubcategoryInDistrict) => {
        try {
            const result = await insertBusinessSubcategoryPermissionsInDistrict(permissions).unwrap();
            console.log("result insert business subcategory permissions in district", result);
            enqueueSnackbar("Berechtige Bezirke für Fachgruppe erfolgreich bearbeitet", { variant: "success" });
        }
        catch (err) {
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleInsertBusinessSubcategoryPermissionsInDistrict, isLoading };
}