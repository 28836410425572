import React, { useContext } from 'react';
import { IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { Edit, DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

import { CompanyContext } from '../../CompanyContext';
import { getAddressRegistration, getPerson, getStatus } from '../../../../../helpers/database';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { swvTheme } from '../../../../../swvTheme';

export default function BusinessTable({ onCompanyLocationOpen, deleteCompanyLocation }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const { businessRegistrationList } = useContext(CompanyContext);

  return (
    <Grid xs={12} mt={3}>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          boxShadow: swvTheme.boxShadow,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}>
            Standorte / Fachgruppen
          </Typography>
        </Box>

        <div className={classes.spaceBetweenTables}>
          {businessRegistrationList?.map((row) => (
            <Table key={row.ID}>
              <TableBody className={cssClass.customTableBody}>
                <TableRow>
                  <TableCell colSpan={2}>
                    <strong>{row?.BusinessSubcategory ? row?.BusinessSubcategory?.Number : row?.Business?.BusinessSubcategory?.Number}</strong>, {row?.BusinessSubcategory ? row?.BusinessSubcategory?.Name : row?.Business?.BusinessSubcategory?.Name}
                  </TableCell>
                  <TableCell align="right" sx={{ paddingLeft: '0 !important', maxWidth: 'none', width: '44px' }}>
                    <IconButton
                      color="primary"
                      onClick={() => deleteCompanyLocation(row)}
                    >
                      <DeleteForever />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => onCompanyLocationOpen(row)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={cssClass.shrinkCell}>Status</TableCell>
                  <TableCell colSpan={2}>{getStatus(row.Status)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ansprechperson</TableCell>
                  <TableCell colSpan={2}>{getPerson(row.AddressRegistration?.ContactPerson?.Person)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Adresse</TableCell>
                  <TableCell colSpan={2}>{getAddressRegistration(row.AddressRegistration)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ))}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'flex-end',
              padding: 2,
              cursor: 'pointer',
            }}
          >
            <AddIcon sx={{ fill: '#F50000' }} onClick={() => onCompanyLocationOpen()} />
          </Box>
        </div>
      </Box>
    </Grid>
  );
}
