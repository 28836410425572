import React from "react"
import { Button, CircularProgress } from "@mui/material"


interface SaveButton {
    onClick: () => void
    disabled: boolean
    loading: boolean
}

export default function SaveButton({ onClick, disabled, loading }: SaveButton) {
    return (
        <Button
            sx={{ maxHeight: "50px", minWidth: "150px" }}
            onClick={onClick}
            disabled={disabled || loading}
        >
            {loading ? <CircularProgress size={"32px"} /> : <span>Speichern</span>}
        </Button>
    )
}