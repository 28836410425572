import { baseQuery } from "../basequery";
import * as endpoints from "../../constants/endpoints";
import { createApi } from '@reduxjs/toolkit/query/react';

export const ElectionProposalAPI = createApi({
    reducerPath: 'ElectionProposal',
    baseQuery,
    tagTypes: ['Proposals'],
    endpoints: (builder) => ({
        getElectionProposal: builder.query({
            query: () => ({
                url: endpoints.getElectionProposal,
                method: 'GET',
            }),
            providesTags: ['Proposals']
        }),
        createElectionProposal: builder.mutation({
            query: (newEntity) => ({
                url: endpoints.insertElectionProposal,
                method: 'POST',
                body: newEntity,
            }),
            invalidatesTags: ['Proposals']
        }),
        updateElectionProposal: builder.mutation({
            query: (updatedEntity) => ({
                url: endpoints.updateElectionProposal,
                method: 'PUT',
                body: updatedEntity,
            }),
            invalidatesTags: ['Proposals']
        }),
        deleteElectionProposal: builder.mutation({
            query: (deletedEntity) => ({
                url: endpoints.deleteElectionProposal,
                method: 'DELETE',
                params: { id: deletedEntity.ID },
            }),
            invalidatesTags: ['Proposals']
        }),
    })
});

export const { useGetElectionProposalQuery, useCreateElectionProposalMutation, useUpdateElectionProposalMutation, useDeleteElectionProposalMutation } = ElectionProposalAPI;