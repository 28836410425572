import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../basequery";
import * as endpoints from '../../constants/endpoints';


export interface GetCompaniesBySubcategory {
    searchText: string,
    subcategoryText: string,
    page: number,
    pageSize: number
}

export const CompanyApi = createApi({
    reducerPath: "CompanyService",
    baseQuery,
    tagTypes: ["CompanyBaseData", "CompanyMasterContact"],
    endpoints: (builder) => ({
        getListBySubcategory: builder.mutation({
            query: (data: GetCompaniesBySubcategory) => ({
                url: endpoints.getCompanyListBySubcategory,
                body: data,
                method: "POST"
            })
        }),
        getCompanyBaseData: builder.query({
            query: (id: number) => ({
                url: endpoints.getCompanyBaseData,
                params: { id },
                method: "GET",
            }),
            providesTags: (result, error, id) => [{ type: "CompanyBaseData", id: id }]
        }),
        getCompanyMasterContactPersonInfo: builder.query({
            query: (id: number) => ({
                url: endpoints.getCompanyMasterContactPersonInfo,
                params: { id },
                method: "GET",
            }),
            providesTags: (result, error, id) => [{ type: "CompanyMasterContact", id: id }]
        })
    })
})

export const { useGetListBySubcategoryMutation, useGetCompanyMasterContactPersonInfoQuery, useGetCompanyBaseDataQuery } = CompanyApi
