import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../basequery";
import * as endpoints from '../../constants/endpoints';

export const AddressRegistrationApi = createApi({
    reducerPath: "AddressRegistrationApi",
    baseQuery,
    tagTypes: ["AddressRegistration"],
    endpoints: (builder) => ({
        getAddressRegistration: builder.query<any, number>({
            query: (id: number) => ({
                url: endpoints.getAddressRegistration,
                params: { id },
                method: "GET",
            }),
            providesTags: (result, error, id) => [{ type: "AddressRegistration", id: id }]
        })
    })
})

export const { useGetAddressRegistrationQuery } = AddressRegistrationApi