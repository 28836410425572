import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../basequery";
import { IBusinessSubcategory } from "../../interfaces/IBusinessSubcategory";

export const BusinessSubcategoryApi = createApi({
    reducerPath: "BusinessSubcategoryApi",
    baseQuery,
    endpoints: (builder) => ({
        getBusinessSubcategoriesByCounty: builder.query<IBusinessSubcategory[], void>({
            query: () => "/BusinessSubcategory/ByCounty",
        }),

    })
})

export const { useGetBusinessSubcategoriesByCountyQuery } = BusinessSubcategoryApi