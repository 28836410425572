import { TableContainer, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useContext, useEffect, useState } from 'react';
import ModalWrapper from '../../common-components/ModalWrapper/ModalWrapper';
import { UserContext } from '../../pages/User/UserContext';
import BusinessSubcategoryUserPermissionsCountyOverviewTable from "./BusinessSubcategoryPermissions/BusinessSubcategoryUserPermissionsCountyOverviewTable";
import BusinessSubcategoryUserPermissionsDistrictOverviewTable from "./BusinessSubcategoryPermissions/BusinessSubcategoryUserPermissionsDistrictOverviewTable";
import GeneralPermissionsCountyTable from "./GeneralPermissions/GeneralPermissionsCountyTable";
import GeneralPermissionsDistrictTable from "./GeneralPermissions/GeneralPermissionsDistrictTable";




export default function UserPermissionPopUp({ isOpen, onClose }) {

    const { currentUser } = useContext(UserContext);

    const [permissionMode, setPermissionMode] = useState("General");
    const [businessSubcategoryTableMode, setBusinessSubcategoryTableMode] = useState("county");
    const [generalTableMode, setGeneralTableMode] = useState("county");

    const handleButtonToggle = (event, newMode) => {
        if (newMode !== null) {
            setBusinessSubcategoryTableMode(newMode);
        }

    }

    const handleButtonTogglePermissionMode = (event, newMode) => {
        if (newMode !== null) {
            setPermissionMode(newMode);
        }
    }

    const handleButtonToggleGeneralMode = (event, newMode) => {
        if (newMode !== null) {
            setGeneralTableMode(newMode);
        }
    }

    useEffect(() => {
        resetState();
    }, [currentUser])

    return (
        <ModalWrapper disableModalContainerScroll isOpen={isOpen} onClose={onClose} title={currentUser ? `Berechtigungen für ${currentUser.Firstname} ${currentUser.Lastname} verwalten` : `Berechtigungen verwalten`}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TableContainer sx={{ maxHeight: "80vh", minHeight: "80vh" }}>

                    <ToggleButtonGroup exclusive onChange={handleButtonTogglePermissionMode} value={permissionMode} style={{ width: "100%", justifyContent: "center", marginBottom: "1em" }}>
                        <ToggleButton value="General">Allgemein</ToggleButton>
                        <ToggleButton value="BusinessSubcategories">Fachgruppe</ToggleButton>
                    </ToggleButtonGroup>

                    {permissionMode === "BusinessSubcategories" ?
                        <>
                            <ToggleButtonGroup exclusive onChange={handleButtonToggle} value={businessSubcategoryTableMode} style={{ width: "100%", justifyContent: "center", marginBottom: "1em" }}>
                                <ToggleButton value="county">Bundesland</ToggleButton>
                                <ToggleButton value="district">Bezirk</ToggleButton>
                            </ToggleButtonGroup>

                            {businessSubcategoryTableMode === "county" ?
                                <BusinessSubcategoryUserPermissionsCountyOverviewTable />
                                :
                                <BusinessSubcategoryUserPermissionsDistrictOverviewTable />

                            }
                        </>
                        :
                        <>
                            <ToggleButtonGroup exclusive onChange={handleButtonToggleGeneralMode} value={generalTableMode} style={{ width: "100%", justifyContent: "center", marginBottom: "1em" }}>
                                <ToggleButton value="county">Bundesland</ToggleButton>
                                <ToggleButton value="district">Bezirk</ToggleButton>
                            </ToggleButtonGroup>

                            {generalTableMode === "county" ?
                                <GeneralPermissionsCountyTable />
                                :
                                <GeneralPermissionsDistrictTable />
                            }
                        </>
                    }



                </TableContainer>

            </LocalizationProvider>
        </ModalWrapper>
    )


    function resetState() {
        setPermissionMode("General");
        setGeneralTableMode("county");
        setBusinessSubcategoryTableMode("county");
    }
}