import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    box: {
      alignItems: 'center',
    },
    paper: {
      borderRadius: '8px',
      padding: '16px',
    },
    title: {
      color: '#9B59B6', 
      fontSize: '20px',
      fontWeight: 600,
      padding: '16px 0',
    },
    contactPersonPaper: {
      borderRadius: '8px',
      cursor: 'pointer',
      padding: '16px',
      transition: 'border-color 0.3s ease-in-out',
    },
    contactPersonName: {
      fontWeight: 500,
    },
    table: {
      borderBottom: 'none',
    },
    tableCellBold: {
      fontWeight: 'bold',
    },
    actionsBox: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      justifyContent: 'flex-end',
      marginTop: '16px',
    },
    addIcon: {
      fill: '#F50000',
      fontSize: '32px',
      '&:hover': {
        transform: 'scale(1.1)',
        transition: 'transform 0.2s ease',
      },
    },
  });