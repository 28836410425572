import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Container,
  SvgIcon,
  Typography,
  Link,
  Box,
  Button,
  Grid,
  Hidden,
  Modal,
  TextField,
  LinearProgress,
  IconButton,
} from '@mui/material';
import QuickActionButtons from '../../common-components/QuickActionButtons';
import SectionHeading from '../../common-components/SectionHeading';
import Navbar from '../Navbar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import DownloadIconV2 from '../../../assets/icons/download-2.svg';
import Grafik from '../../../assets/images/Grafik_EPUundKMU.jpg';
import { styles } from './styles';
import * as PersonService from '../../../services/requests/person';
import DataCard from '../../common-components/DataCard';
import { ArrowForward, CloudUpload, Delete, Note, NoteAdd, Subject, Upload, UploadFile } from '@mui/icons-material';
import { deleteSwvInfoFiles, getSwvInfoFiles, uploadStaticFile } from '../../../services/requests/file';
import TextInput from '../../common-components/TextInput';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40vw",
  bgcolor: 'background.paper',
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

export const SWVInfo = () => {
  const theme = useTheme();

  const [windowWidth, setWindowWidth] = useState(0);

  const [infoFiles, setInfoFiles] = useState([]);

  useEffect(() => {
    getSwvInfoFiles(null, null, (data) => {
      setInfoFiles(data);
    })
  }, []);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }

  const handleFileUpload = async (event) => {
    const formData = new FormData();
    for (const file in event.target.files) {
      formData.append('files', file);
    }

    formData.append('AddToSWVInfo', 'true');

    await uploadStaticFile(formData,
      (data) => {
        console.log("RESULT", data);
      },
      (error) => {
        console.log("ERROR", error);
      });
  };

  const inputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let files = []
      let notes = []
      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
        notes.push("");
      }
      setSelectedFiles(files);
      setUploadStatus("selected");
      setNotes(notes);
      console.log("FILES", files, JSON.stringify(files));
    }
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const clearFileInput = (index) => {
    if (selectedFiles?.length === 1 || index === undefined) {
      inputRef.current.value = "";
      setSelectedFiles(null);
      setProgress(0);
      setUploadStatus("select");
    } else {
      setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    };
  }
  useEffect(() => {
    if (uploadStatus === "done") {
      getSwvInfoFiles(null, null, (data) => {
        setInfoFiles(data);
      })
    }
  }, [uploadStatus]);

  const handleUpload = async () => {
    console.log("Handle Upload");
    if (uploadStatus === "done") {
      clearFileInput();
      return;
    }

    try {
      setUploadStatus("uploading");

      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }

      try {
        formData.append("notes", JSON.stringify(notes));
        formData.append("AddToSWVInfo", "true");
      } catch (e) {
        console.log("ERROR", e);
      }

      const response = await uploadStaticFile(formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        },
        (data) => {
          setUploadStatus("done");
        },
      );

      setUploadStatus("done");
      setSelectedFiles(null);
    } catch (error) {
      setUploadStatus("select");
    }
  };

  const [notes, setNotes] = useState({});
  const userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    infoFiles?.forEach((file) => {
      console.log("FILE", file, `${process.env.REACT_APP_FILE_URL}`,`${file.StaticFile.Uri}`);
      console.log("process.env.REACT_APP_BE_URL", process.env.REACT_APP_BE_URL)
    })
  }, [infoFiles]);
  return (
    <>
      {/*<QuickActionButtons />*/}
      <Container >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Datei(en) hinzufügen
              </Typography>
              <IconButton sx={{ height: "1em" }} onClick={() => { setOpen(false) }}>X</IconButton>
            </div>
            {uploadStatus === "done" &&
              <Typography fontWeight={"500"} sx={{ mt: 2, mb: 2 }}>Ihr Upload war erfolgreich</Typography>
            }
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
              Hier können Sie neue Dateien hochladen.
            </Typography>
            <div>
              {selectedFiles && (
                <>
                  {selectedFiles?.map((file, i) => (
                    <div key={i}>
                      <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "0.33em" }}>
                        <Typography textOverflow={'ellipsis'}>{i + 1}. {file.name}</Typography>
                        <div>
                          <IconButton sx={{ height: "1em" }} onClick={() => clearFileInput(i)}>X</IconButton>
                        </div>
                      </div>
                      <TextInput placeholder={"Notiz"} onChange={(val) => { setNotes((old) => (old.map((value, index) => { if (index === i) { return val.target.value } else { return value } }))) }} />
                    </div>
                  ))}
                  {uploadStatus === "done" &&
                    <Typography sx={{ mt: 2 }}>Ihr Upload war erfolgreich</Typography>
                  }
                  {uploadStatus === "uploading" &&
                    <LinearProgress variant='determinate' value={progress} sx={{ mt: 2 }} />
                  }
                  <Button className="upload-btn" onClick={handleUpload} sx={{ mt: 2 }} >
                    {"Hochladen"}
                  </Button>
                </>
              )}
            </div>
            <div>
              <input
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                multiple
              />
              {/* Button to trigger the file input dialog */}
              {!selectedFiles && (
                <Button className="file-btn" onClick={onChooseFile}>
                  Datei(en) wählen
                </Button>
              )}
            </div>
          </Box >
        </Modal >
        <Hidden smDown>
          <Box
            sx={{
              '@media (max-width: 685px)': {
                boxShadow: 2,
                background: "white",
                marginY: "1.5rem",
                padding: '0.8rem'
              }
            }}
            paddingTop={'1.5rem'}
            style={{ borderRadius: '10px' }}
            display={'flex'}
            flexDirection={'column'}
          >
            <Typography variant="h1" fontWeight={'400'}>
              Informationen des SWV
            </Typography>
            <Typography paddingTop={'10px'} sx={styles.infoText}>
              Willkommen im Info-Bereich des Sozialdemokratischen
              Wirtschaftsverbands Österreich! Hier findest du aktuelle Updates
              und Einblicke in die Wirtschaftswelt. Wir setzen uns für kleine
              und mittlere Unternehmen ein. Entdecke Trends, erhalte Tipps und
              bleib immer einen Schritt voraus!
            </Typography>
          </Box>
        </Hidden>
        <Box

          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'start',
            '@media (min-width: 0px) and (max-width: 685px)': {
              gap: '1rem',
            },
            justifyContent: 'space-between',
            paddingY: 'initial',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              paddingY: '1.5rem',

            },
          }}
        >
          <Hidden smUp>
            <Box

              boxShadow={2}
              padding={'0.8rem'}
              style={{ borderRadius: '10px', background: "white" }}
              display={'flex'}
              flexDirection={'column'}
            >
              <Typography variant="h1" fontWeight={'400'}>
                Informationen des SWV
              </Typography>
              <Typography paddingTop={'10px'} sx={styles.infoText}>
                Willkommen im Info-Bereich des Sozialdemokratischen
                Wirtschaftsverbands Österreich! Hier findest du aktuelle Updates
                und Einblicke in die Wirtschaftswelt. Wir setzen uns für kleine
                und mittlere Unternehmen ein. Entdecke Trends, erhalte Tipps und
                bleib immer einen Schritt voraus!
              </Typography>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box
              width={{ sm: "33%", xs: "100%" }}
              boxSizing={'border-box'}
              padding={'0.8rem'}
              boxShadow={2}

              style={{ background: 'white', borderRadius: '10px' }}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box component={"img"} src={Grafik} width={"100%"} />
              <Typography
                style={{ margin: 0 }}
                paddingY={'1rem'}
                fontWeight={'500'}
                color={'rgba(115, 35, 130, 1)'}
                fontSize={'20px'}
                variant="span"
                sx={styles.infoText}
              >
                Wir sind 99,6 Prozent!
              </Typography>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                  <CalendarMonthIcon sx={{ color: 'rgba(0,0,0,0.6)' }} />
                  <Typography
                    style={{ margin: 0 }}
                    fontSize={'13px'}
                    variant="body1"
                    sx={styles.infoText}
                  >
                    23. September 2021
                  </Typography>
                </Box>
                <button
                  display={'flex'}
                  style={{
                    fontSize: 13,
                    background: 'rgba(115, 35, 130, 1)',
                    appearance: 'none',
                    padding: '15px 1rem',
                    color: 'white',
                    fontWeight: 600,
                    borderRadius: '5px',
                    width: 'auto',
                    border: 'none',
                    outline: 'none',

                  }}

                >
                  Mehr lesen
                </button>
              </Box>
            </Box>
          </Hidden>
          <Box
            width={{ sm: "38%" }}
            sx={{
              '@media (min-width: 600px) and (max-width: 685px)': {
                width: "100%",
              },
              '@media (min-width: 685px) and (max-width: 1100px)': {
                width: "48%",
              },
              '@media (min-width: 1100px)': {
                minWidth: 400,
              },

            }}
            boxShadow={2}
            padding={'0.8rem'}
            style={{ background: 'white', borderRadius: '10px' }}
            display={'flex'}
            flexDirection={'column'}
          >
            <Typography variant="h1" fontWeight={'400'}>
              Downloadbereich des SWV
            </Typography>
            <Typography paddingTop={'10px'} sx={styles.infoText}>
              Willkommen im Info-Bereich des Sozialdemokratischen
              Wirtschaftsverbands Österreich! Hier findest du aktuelle Updates
              und Einblicke in die Wirtschaftswelt. Wir setzen uns für kleine
              und mittlere Unternehmen ein. Entdecke Trends, erhalte Tipps und
              bleib immer einen Schritt voraus!
            </Typography>
            <Box

              justifyContent={'space-evenly'}
              gap={2}
              display={'flex'}
              flexWrap={'wrap'}

            >
              {infoFiles?.map(
                (swvInfoFile, i) =>
                (
                  <Box
                    sx={{
                      position: 'relative',
                      '@media (max-width: 381px)': {
                        width: "100%",
                      }
                    }}
                    textAlign={'center'}
                    padding={2}
                    minWidth={'100px'}
                    width={"33%"}
                    key={swvInfoFile.ID}
                    item
                    xs={6}
                    style={{
                      background: 'rgba(217, 217, 217, 0.5)',
                      borderRadius: '8px',
                      border: '1.5px solid rgba(115, 35, 130, 1)',
                    }}
                  >
                    {userData.Admin && <IconButton
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        height: "1em",
                        width: "1em",
                      }}
                      onClick={() => {
                        deleteSwvInfoFiles({ id: swvInfoFile.ID }, () => {
                          getSwvInfoFiles(null, null, (data) => {
                            setInfoFiles(data);
                          })
                        })
                      }}  // Optional: Add click handler for the button
                    >
                      <Delete style={{ color: "red" }} /> {/* Replace with your icon component */}
                    </IconButton>}
                    <Link
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      style={{ wordBreak: 'break-all', marginBottom: "1em" }}
                      sx={styles.link}
                      href={`${process.env.REACT_APP_FILE_URL}${swvInfoFile.StaticFile.Uri}`}
                      download={swvInfoFile.StaticFile.FileName}
                      key={swvInfoFile.ID}
                    >
                      <Box
                        style={{
                          background: 'rgba(115, 35, 130, 1)',
                          borderRadius: '100%',
                          padding: '10px',
                          color: 'white',

                        }}
                      >
                        <Box component={"img"} src={DownloadIconV2} width={"35px"} />
                      </Box>
                      <span>{swvInfoFile.StaticFile.FileName}</span>
                    </Link>
                    <span >{swvInfoFile.Note}</span>
                  </Box>
                ),
              )}
              {userData.Admin && <Button onClick={() => { setOpen(true) }}>Datei(en) hinzufügen</Button>}
            </Box>
          </Box>
          <Hidden smDown>
            <Box
              sx={{
                '@media (min-width: 600px) and (max-width: 685px)': {
                  width: "100%",
                },
                '@media (min-width: 685px) and (max-width: 1100px)': {
                  width: "48%",

                },
              }}
              width={{ sm: "27%", xs: "100%" }}
              minWidth={300}
              boxSizing={'border-box'}
              padding={'0.8rem'}
              boxShadow={2}

              style={{ background: 'white', borderRadius: '10px' }}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box component={"img"} src={Grafik} width={"100%"} />
              <Typography
                style={{ margin: 0 }}
                paddingY={'1rem'}
                fontWeight={'500'}
                color={'rgba(115, 35, 130, 1)'}
                fontSize={'20px'}
                variant="span"
                sx={styles.infoText}
              >
                Wir sind 99,6 Prozent!
              </Typography>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                  <CalendarMonthIcon sx={{ color: 'rgba(0,0,0,0.6)' }} />
                  <Typography
                    style={{ margin: 0 }}
                    fontSize={'13px'}
                    variant="body1"
                    sx={styles.infoText}
                  >
                    23. September 2021
                  </Typography>
                </Box>
                <button
                  display={'flex'}
                  style={{
                    fontSize: 13,
                    background: 'rgba(115, 35, 130, 1)',
                    appearance: 'none',
                    padding: '15px 1rem',
                    color: 'white',
                    fontWeight: 600,
                    borderRadius: '5px',
                    width: 'auto',
                    border: 'none',
                    outline: 'none',

                  }}

                >
                  Mehr lesen
                </button>
              </Box>
            </Box>
          </Hidden>
          <Hidden smDown>
            <Box
              sx={{
                '@media (min-width: 600px) and (max-width: 685px)': {
                  width: "100%",
                },
                '@media (min-width: 685px) and (max-width: 1100px)': {
                  width: "48%",
                  marginTop: "1.5rem"
                },

              }}
              style={{ background: 'rgba(115, 35, 130, 0.1)', borderRadius: '10px' }}
              boxShadow={2}
              padding={'0.8rem'}
              marginBottom={'4.10rem'}
              display={'flex'}
              flexDirection={'column'}
              width={"27%"}
              minWidth={300}
            >
              <Typography
                paddingTop={'10px'}
                style={{ marginBottom: '15px' }}
                sx={styles.infoText}
              >
                Neue Mitglieder in deinem Bezirk:
              </Typography>
              <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
                <DataCard
                  lineOne={'XYZ Firma gmbH ❤️'}
                  lineTwo="1060 - FG Nr. : 606036"
                  placeholderImage="person"
                />
                <DataCard
                  lineOne={'Harry Mustermann'}
                  lineTwo="1060 - FG Nr. : 606036"
                  placeholderImage="person"
                />
                <DataCard
                  lineOne={'Musterfirma GmbH'}
                  lineTwo="1060 - FG Nr. : 606036"
                  placeholderImage="person"
                />
              </Box>
              <Box width={"100%"} display={"flex"} alignItems={"center"} gap={2} paddingY={3} justifyContent={"center"} >
                <Typography

                  style={{ marginBottom: 0 }}
                  sx={styles.infoText}
                >
                  Alle neuen Mitglieder ansehen
                </Typography>
                <ArrowForward fontSize="small"
                  color="#732382"
                />
              </Box>
            </Box>
          </Hidden>
        </Box>

      </Container >
      <Hidden smUp>
        <Box
          style={{ background: 'rgba(115, 35, 130, 0.1)' }}
          padding={'0.8rem'}
          marginBottom={'4.10rem'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Typography
            paddingTop={'10px'}
            style={{ marginBottom: '15px' }}
            sx={styles.infoText}
          >
            Neue Mitglieder in deinem Bezirk:
          </Typography>
          <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
            <DataCard
              lineOne={'XYZ Firma gmbH ❤️'}
              lineTwo="1060 - FG Nr. : 606036"
              placeholderImage="person"
            />
            <DataCard
              lineOne={'Harry Mustermann'}
              lineTwo="1060 - FG Nr. : 606036"
              placeholderImage="person"
            />
            <DataCard
              lineOne={'Musterfirma GmbH'}
              lineTwo="1060 - FG Nr. : 606036"
              placeholderImage="person"
            />
          </Box>
          <Box width={"100%"} display={"flex"} alignItems={"center"} gap={2} paddingY={3} justifyContent={"center"} >
            <Typography

              style={{ marginBottom: 0 }}
              sx={styles.infoText}
            >
              Alle neuen Mitglieder ansehen
            </Typography>
            <ArrowForward fontSize="small"
              color="#732382"
            />
          </Box>
        </Box>
      </Hidden>
    </>
  );
};
