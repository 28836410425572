import { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import * as UserService from '../../../services/requests/user';

import { useSnackbarHelpers } from '../../../helpers/utils';

import EditUserPopUp from '../../popups/User';
import CheckCell from '../../common-components/CheckCell';
import DeleteCell from '../../common-components/DeleteCell';
import SectionHeading from '../../common-components/SectionHeading';

import { chooseCounty, getPerson } from '../../../helpers/database';
import { defaultUser, UserContext } from './UserContext';
import { useGlobalStyles } from '../../../customStyles';
import { Messages } from '../../../constants/messages';
import BoxCenter from '../../common-components/BoxCenter';
import UserPermissionPopUp from '../../popups/UserPermissions/UserPermissions';
import { getUserRoleName } from '../../../helpers/helperFunctions';
import CreateUserPopUp from '../../popups/User/CreateUser';
import { useHandleCreateUser, useHandleDeleteUser, useHandleUpdateUser } from '../../../redux/services/User/UserRequestHandlers';
import { useGetAllUsersQuery } from '../../../redux/services/User/UserService';
import { IUser } from '../../../interfaces/IUser';
import UserListFilter, { filterUserList, UserFilters } from './UserListFilter';

export default function User() {
  const cssClass = useGlobalStyles();
  const [currentUser, setCurrentUser] = useState(defaultUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userPermissionModalOpen, setUserPermissionModalOpen] = useState(false);

  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarHelpers();
  const { handleUpdateUser, isLoading: isUpdateUserLoading } = useHandleUpdateUser();
  const { handleCreateUser, isLoading: isCreateUserLoading } = useHandleCreateUser();
  const { handleDeleteUser, isLoading: isDeleteUserLoading } = useHandleDeleteUser();
  const { data: userList } = useGetAllUsersQuery();

  const [filteredUserList, setFilteredUserList] = useState<IUser[]>([]);

  useEffect(() => {
    if (userList) {
      setFilteredUserList(userList);
    }
  }, [userList])

  useEffect(() => {
    setCurrentUser(defaultUser);
  }, []);

  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);

  const onRowClicked = (clickedUser: IUser) => {
    setCurrentUser(clickedUser);
    setIsEditUserModalOpen(true);
  };

  const onDeleteClicked = async (id: number) => {
    await handleDeleteUser(id);
  };

  const onNewClicked = () => {
    setCurrentUser(defaultUser);
    setIsModalOpen(true);
  };

  // UserPopUp

  const onInviteClicked = async (user: IUser) => {
    if (user.IsActive) {
      const result = await UserService.inviteOne(user.ID);
      if (result.isSuccess) {
        showSuccessSnackbar(Messages.Invited);
      }
      else {
        showErrorSnackbar(Messages.Error);
      }
    }
    else {
      showErrorSnackbar(Messages.ErrorUserInactive);
    }
  }

  const onSaveClicked = async (user: IUser) => {
    if (user.ID && user.ID > 0) {
      await handleUpdateUser(user);
    } else {
      await handleCreateUser(user);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleEditUserModalClose = () => {
    setIsEditUserModalOpen(false);
  }

  const handleManagePermissionClicked = () => {
    setIsModalOpen(false);
    setUserPermissionModalOpen(true);
  }

  const handlePermissionModalClose = () => {
    setUserPermissionModalOpen(false);
    setIsEditUserModalOpen(true);
  }

  const onUserFilterChange = (filters: UserFilters) => {
    if (userList) {
      filterUserList(userList, setFilteredUserList, filters);
    }
  }

  return (
    <UserContext.Provider
      value={{
        currentUser,
      }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            position: 'relative',
            padding: '22px 0 22px',
          }}
        >
          <SectionHeading title="Benutzerverwaltung" sx={{ margin: '0 auto' }} />
        </Box>
        <UserListFilter onChange={(filters) => onUserFilterChange(filters)} />
        <TableContainer sx={{ maxHeight: "65vh" }}>
          <Table sx={{ minWidth: 450 }}>
            <TableHead>
              <TableRow>
                <TableCell className={cssClass.mediumCell}>
                  Benutzername
                </TableCell>
                <TableCell className={cssClass.largeCell}>Email</TableCell>
                <TableCell className={cssClass.mediumCell}>Name</TableCell>
                <TableCell className={cssClass.userRoleCell + " " + cssClass.centeredCellText}>Rolle</TableCell>
                <TableCell className={cssClass.smallCell}>Bundesland</TableCell>
                <TableCell className={cssClass.btnCell}>Admin</TableCell>
                <TableCell className={cssClass.btnCell}>Aktiv</TableCell>
                <TableCell className={cssClass.btnCell}>Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUserList && filteredUserList.map((row) => (
                <TableRow
                  key={row.ID}
                  selected={currentUser?.ID == row.ID}
                  onClick={() => onRowClicked(row)}
                >
                  <TableCell>{row.Username}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{getPerson(row)}</TableCell>
                  <TableCell className={cssClass.centeredCellText}>{getUserRoleName(row.UserRole)}</TableCell>
                  <TableCell>{chooseCounty(row.County)}</TableCell>
                  <CheckCell flag={row.IsAdmin} />
                  <CheckCell flag={row.IsActive} />
                  <DeleteCell func={onDeleteClicked} id={row.ID} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BoxCenter>
          <Button
            onClick={() => onNewClicked()}
          >
            Benutzer Erstellen
          </Button>
        </BoxCenter>
      </Container>

      <CreateUserPopUp
        isOpen={isModalOpen}
        onClose={handleClose}
        onSaveClicked={onSaveClicked}
        isCreateUserLoading={isCreateUserLoading}
      />
      <EditUserPopUp
        isOpen={isEditUserModalOpen}
        onClose={handleEditUserModalClose}
        onInviteClicked={onInviteClicked}
        onSaveClicked={onSaveClicked}
        onManagePermissionClicked={() => handleManagePermissionClicked()}
        loading={isUpdateUserLoading}
      />
      <UserPermissionPopUp
        isOpen={userPermissionModalOpen}
        onClose={handlePermissionModalClose}
      />
    </UserContext.Provider>
  );
};