import React, { useCallback, useEffect, useState, useRef } from 'react';
import SearchIcon from "@mui/icons-material/Close";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';

import { useStyles } from './styles';

export default function TextInput({ placeholder, onChange, value, size }) {
  const classes = useStyles();

  // const [value, setValue] = useState(value);

  const inputRef = useRef<HTMLInputElement>(null); // fixme

  return (
    <div className={classes.mainContainer}>
      <div className={classes.labelContainer2}>
        {value && <div className={classes.labelContainer}>
          <label htmlFor="my-select" className={classes.label}>{placeholder}</label>
        </div>}
      </div>

      <OutlinedInput
        id="outlined-adornment-password"
        value={value}
        onChange={onChange}
        type={'text'}
        margin="dense"
        fullWidth
        // endAdornment={
        //   <InputAdornment position="end" onClick={() => 
        //     {
        //       setValue("");

        //       if (inputRef.current)
        //         inputRef.current.value = "asd";
        //     }}>
        //     <SearchIcon />
        //   </InputAdornment>
        // }
        placeholder={placeholder}
      />
    </div>
  )
}
