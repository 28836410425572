import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import AppSlice from './slices/appSlice';
// import FavoriteSlice from './slices/favoriteSlice';
import CompanySlice from './slices/companySlice';
import PersonSlice from './slices/personSlice';
import AddressSlice from './slices/addressSlice';
import ContactPersonSlice from './slices/contactPersonSlice';
import BusinessSubcategorySlice from './slices/businessSubcategorySlice';
import { logger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { BusinessCategoryAPI } from "./services/BusinessCategoryService";
import { OrganizationAPI } from "./services/OrganizationService";
import { PersonAPI } from "./services/PersonService";
import { PanelAPI } from "./services/PanelService";
import { BusinessAPI } from "./services/BusinessService";
import { FormAPI } from './services/FormService';
import { BusinessSubcategoryApi } from "./services/BusinessSubCategoryService";
import { UserPermissionsApi } from './services/UserPermissions/UserPermissionsService';
import { CompanyApi } from './services/CompanyService';
import { ContactPersonApi } from './services/ContactPersonService';
import { ElectionProposalAPI } from './services/ElectionProposalService';
import { DistrictApi } from './services/DistrictService';
import { UserApi } from './services/User/UserService';
import { ChangeProposalApi } from './services/ChangeProposalService';
import { AddressRegistrationApi } from './services/AddressRegistrationService';
import { CommunicationDataApi } from './services/CommunicationDataService';
import { GoalStatusAPI } from './services/GoalStatusService';
import { TreeViewAPI } from './services/TreeViewService';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    savePreviousLocations: 5,
    basename: '/',
  });

const middlewares = [routerMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const rootReducer = combineReducers({
  app: AppSlice,
  [FormAPI.reducerPath]: FormAPI.reducer,
  [ElectionProposalAPI.reducerPath]: ElectionProposalAPI.reducer,
  [BusinessCategoryAPI.reducerPath]: BusinessCategoryAPI.reducer,
  [BusinessSubcategoryApi.reducerPath]: BusinessSubcategoryApi.reducer,
  [OrganizationAPI.reducerPath]: OrganizationAPI.reducer,
  [PersonAPI.reducerPath]: PersonAPI.reducer,
  [PanelAPI.reducerPath]: PanelAPI.reducer,
  [BusinessAPI.reducerPath]: BusinessAPI.reducer,
  [CompanyApi.reducerPath]: CompanyApi.reducer,
  [ContactPersonApi.reducerPath]: ContactPersonApi.reducer,
  [UserPermissionsApi.reducerPath]: UserPermissionsApi.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  [DistrictApi.reducerPath]: DistrictApi.reducer,
  [ChangeProposalApi.reducerPath]: ChangeProposalApi.reducer,
  [AddressRegistrationApi.reducerPath]: AddressRegistrationApi.reducer,
  [CommunicationDataApi.reducerPath]: CommunicationDataApi.reducer,
  [GoalStatusAPI.reducerPath]: GoalStatusAPI.reducer,
  [TreeViewAPI.reducerPath]: TreeViewAPI.reducer,
  company: CompanySlice,
  person: PersonSlice,
  address: AddressSlice,
  contactPerson: ContactPersonSlice,
  router: routerReducer,
  businessSubCategory: BusinessSubcategorySlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware:
    (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(...middlewares)
      .concat(BusinessAPI.middleware)
      .concat(PanelAPI.middleware)
      .concat(PersonAPI.middleware)
      .concat(OrganizationAPI.middleware)
      .concat(BusinessCategoryAPI.middleware)
      .concat(FormAPI.middleware)
      .concat(ElectionProposalAPI.middleware)
      .concat(CompanyApi.middleware)
      .concat(ContactPersonApi.middleware)
      .concat(BusinessSubcategoryApi.middleware)
      .concat(UserPermissionsApi.middleware)
      .concat(UserApi.middleware)
      .concat(DistrictApi.middleware)
      .concat(ChangeProposalApi.middleware)
      .concat(AddressRegistrationApi.middleware)
      .concat(CommunicationDataApi.middleware)
      .concat(GoalStatusAPI.middleware)
      .concat(TreeViewAPI.middleware)

      //.concat(loaderMiddleware) //needs to be concatenated after all the api middlewares, else will cause issues with RTK Query
});

export const history = createReduxHistory(store);
