import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Avatar, Button, useMediaQuery } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CloseIcon from '@mui/icons-material/Close';

import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { subject } from '../../../service';
import HistoryPopUp from '../../popups/History';
import ProfilePopUp from '../../popups/Profile';
import FavoritePopUp from '../../popups/Favorite';
import PersonPopUp from '../../popups/Person';
import CompanyPopUp from '../../popups/Company';
import UserGoalPopUp from '../../popups/UserGoal/UserGoal';
import * as endpoints from '../../../constants/endpoints';
import FormLink from '../../common-components/FormLink';
import NavMenu from '../../popups/NavMenu';
import FavoriteIcon from '@mui/icons-material/Favorite';
import * as PersonService from '../../../services/requests/person';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { NavbarContext } from './NavbarContext';
import SWVIcon from '../../common-components/SWVIcon';
import { urlIsLoadable } from '../../../helpers/utils';
import { hasElevatedPrivileges } from '../../../helpers/helperFunctions';
import { useGetCurrentUserQuery } from '../../../redux/services/User/UserService';

export default function Navbar({ children }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { data: currentUser, error, isLoading: isCurrentUserLoading } = useGetCurrentUserQuery()


  const [anchorEl, setAnchorEl] = useState(null);
  const [openAdminEl, setOpenAdminEl] = useState(null);

  const [username, setUsername] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [chosenPerson, setChosenPerson] = useState(null);

  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isFavoriteModalOpen, setIsFavoriteModalOpen] = useState(false);
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isUserGoalModalOpen, setIsUserGoalModalOpen] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(['refreshToken']);
  const navigate = useNavigate();
  const location = useLocation();

  function setValues() {
    const userData = JSON.parse(localStorage.getItem('userData'));

    setUsername(userData.Username);
    setIsAdmin(userData.Admin);

    const photoUrl = userData.Photo ? endpoints.apiUrlEx + endpoints.getProfilePhotoSrc + "?id=" + userData.ID + "&date=" + Date.now() : null

    urlIsLoadable(photoUrl, setPhotoURL);
  }

  useEffect(() => {
    setValues();
    subject.subscribe((o) => { if (o) { setValues(); } });
  }, []);

  const onHomeClicked = async () => {
    if (location.pathname == '/') {
      // reload page
      navigate(0);
    } else {
      navigate('/', { replace: false });
    }
  };

  // general menu (navigation menu)

  const onGeneralMenuClicked = (event) => {
    setIsNavMenuOpen(!isNavMenuOpen);
  };

  // profile menu

  const onProfileMenuClicked = (event) => {
    onProfileClicked();
    // setAnchorEl(event.currentTarget);
  };

  const onProfileMenuClose = () => {
    setAnchorEl(null);
  };

  // general menu items

  const onUserClicked = (e) => {
    e.preventDefault();
    navigate('/benutzer', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onCheckClicked = (e) => {
    e.preventDefault();
    navigate('/kontrolle', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onMunicipalityClicked = (e) => {
    e.preventDefault();
    navigate('/gemeinden', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onPersonClicked = (e) => {
    e.preventDefault();
    setChosenPerson(null);
    setIsPersonModalOpen(true);
    setOpenAdminEl(null);
    setIsNavMenuOpen(false);
  };

  const onCompanyInsertClicked = (e) => {
    e.preventDefault();
    setIsCompanyModalOpen(true);
    setOpenAdminEl(null);
    setIsNavMenuOpen(false);
  };

  const onGoalsClicked = (e) => {
    e.preventDefault();
    navigate('/ziele', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onHonorClicked = (e) => {
    e.preventDefault();
    navigate('/ehrungen', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onOrganisationClicked = (e) => {
    e.preventDefault();
    navigate('/organisationen', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onOrganisationSearchClicked = (e) => {
    e.preventDefault();
    navigate('/organisationssuche', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onSWVInfoClicked = (e) => {
    e.preventDefault();
    navigate('/swv-info', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onFormulareClicked = (e) => {
    e.preventDefault();
    navigate('/formulare', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onFormularversandClicked = (e) => {
    e.preventDefault();
    navigate('/formularversand', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onElectionProposalClicked = (e) => {
    e.preventDefault();
    navigate('/wahlvorschlaege', { replace: false });
    setIsNavMenuOpen(false);
  };

  const onChangeProposalClicked = (e) => {
    e.preventDefault();
    navigate('/aenderungsvorschlaege', { replace: false });
    setIsNavMenuOpen(false);
  }

  const onPersonModalClose = () => {
    setIsPersonModalOpen(false);
  };

  const onCompanyModalClose = () => {
    setIsCompanyModalOpen(false);
  };

  // profile menu items open

  const onFavoriteClicked = () => {
    setIsFavoriteModalOpen(true);
    setAnchorEl(null);
  };

  const onHistoryClicked = () => {
    setIsHistoryModalOpen(true);
    setAnchorEl(null);
  };

  const onUserGoalClicked = () => {
    setIsUserGoalModalOpen(true);
    setAnchorEl(null);
  };

  const onProfileClicked = () => {
    setIsProfileModalOpen(true);
    setAnchorEl(null);
  };

  const onLogoutClicked = () => {
    removeCookie();
    localStorage.clear();
    navigate('/login', { replace: false });
  };

  // profile menu items close

  const onHistoryModalClose = () => {
    setIsHistoryModalOpen(false);
  };

  const onProfileModalClose = () => {
    setIsProfileModalOpen(false);
  };

  const onFavoriteModalClose = () => {
    setIsFavoriteModalOpen(false);
  };

  const onUserGoalModalClose = () => {
    setIsUserGoalModalOpen(false);
  };

  const isMobile = useMediaQuery("(max-width: 850px)")

  return (
    <NavbarContext.Provider
      value={{
        chosenPerson,
        setChosenPerson,
      }}
    >
      {isMobile &&
        <>
          <AppBar position="fixed" elevation={0} sx={{ padding: "0px !important", height: "7vh", width: "100vw", left: 0 }}>
            <Toolbar style={{ zIndex: 520 }} sx={{ padding: "0px !important", height: "100%", display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  /*display: { xs: 'flex' },
                  '& > :not(style) + :not(style)': {
                    //ml: 3,
                  },
                  '&:hover': {
                    cursor: 'pointer',
                  },*/
                  paddingLeft: isMobile ? "1em" : "0px",
                  display: "flex",
                  width: "12vw",
                  justifyContent: "center"
                }}
                onClick={() => onHomeClicked()}
              >
                <SWVIcon />
              </Box>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div onClick={() => setIsFavoriteModalOpen(true)}>
                  <FavoriteIcon style={{ fontSize: "22px", color: "white" }} />
                </div>
                <IconButton
                  size="large"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={onProfileMenuClicked}
                >
                  <Avatar
                    style={{ width: '30px', height: '30px' }}
                    src={photoURL}>
                    <PersonIcon />
                  </Avatar>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => onProfileMenuClose()}
                >
                  <MenuItem onClick={onFavoriteClicked}>Favoriten</MenuItem>
                  <MenuItem onClick={onHistoryClicked}>Verlauf</MenuItem>
                  <MenuItem onClick={onUserGoalClicked}>Ziele</MenuItem>
                  <MenuItem onClick={onProfileClicked}>Profil</MenuItem>
                  <MenuItem onClick={onLogoutClicked}>Abmelden</MenuItem>
                </Menu>
              </div>
              <Box className={cssClass.hideOnDesktop}>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={onGeneralMenuClicked}>
                  {isNavMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <div style={{ width: "100%", marginTop: "7vh", minHeight: "100%", paddingBottom: "3em" }}>
            {children}
          </div>
          <div style={{ position: "fixed", top: "7vh", zIndex: 11 }}>
            <NavMenu
              isOpen={isNavMenuOpen}
              setIsOpen={setIsNavMenuOpen}>
              <Box sx={{ padding: '17px 0' }} />
              {isAdmin && <MenuItem onClick={onUserClicked}>Benutzer</MenuItem>}
              <MenuItem onClick={onCheckClicked}>Kontrolle</MenuItem>
              {/*<MenuItem onClick={onFormulareClicked}>Formulare</MenuItem>*/}
              {/* <MenuItem onClick={onFormularversandClicked}>Formularversand</MenuItem> */}
              <MenuItem onClick={onPersonClicked}>Personen</MenuItem>
              {/* <MenuItem onClick={onMunicipalityClicked}>Gemeinden</MenuItem> */}
              <MenuItem onClick={onCompanyInsertClicked}>Firma anlegen</MenuItem>
              <MenuItem onClick={onGoalsClicked}>Ziele</MenuItem>
              {/* <MenuItem onClick={onHonorClicked}>Ehrungen</MenuItem> */}
              {/* <MenuItem onClick={onOrganisationClicked}>Organisationen</MenuItem> */}
              {/* <MenuItem onClick={onElectionProposalClicked}>Wahlvorschläge</MenuItem> */}
              <MenuItem onClick={onSWVInfoClicked}>SWV Info</MenuItem>
              <Box sx={{ padding: '34px 0' }} />
              <PowerSettingsNewIcon sx={{ padding: '6px 0' }} onClick={onLogoutClicked} />
              <Box sx={{ padding: '34px 0' }} />
            </NavMenu>
          </div>
        </>}

      {!isMobile &&
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <div style={{ zIndex: 1, position: "fixed" }}>
            <Box
              sx={{
                /*display: { xs: 'flex' },
                '& > :not(style) + :not(style)': {
                  //ml: 3,
                },*/
                '&:hover': {
                  cursor: 'pointer',
                },
                paddingLeft: isMobile ? "1em" : "0px",
                display: "flex",
                width: "12vw",
                height: "7vh",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F50000"
              }}
              onClick={() => onHomeClicked()}
            >
              <SWVIcon />
            </Box>
            <NavMenu
              isOpen={isNavMenuOpen}
              setIsOpen={setIsNavMenuOpen}>
              <Box sx={{ padding: '17px 0' }} />
              {isAdmin && <MenuItem ><a href="/benutzer" onClick={onUserClicked} >Benutzer</a></MenuItem>}
              <MenuItem><a href="/kontrolle" onClick={onCheckClicked}>Kontrolle</a></MenuItem>
              <MenuItem><a href="/formulare" onClick={onFormulareClicked}>Formulare</a></MenuItem>
              <MenuItem><a href="/formularversand" onClick={onFormularversandClicked}>Formularversand</a></MenuItem>
              <MenuItem className='menuItem' onClick={onPersonClicked}>Personen</MenuItem>
              {/*<MenuItem onClick={onMunicipalityClicked}>Gemeinden</MenuItem> */}
              <MenuItem className='menuItem' onClick={onCompanyInsertClicked}>Firma anlegen</MenuItem>
              <MenuItem><a href="/ziele" onClick={onGoalsClicked}>Ziele</a></MenuItem>
              <MenuItem><a href="/ehrungen" onClick={onHonorClicked}>Ehrungen</a></MenuItem>
              <MenuItem><a href="/organisationen" onClick={onOrganisationClicked}>Organisationen</a></MenuItem>
              <MenuItem><a href="/organisationssuche" onClick={onOrganisationSearchClicked}>Fraktionssuche</a></MenuItem>
              <MenuItem><a href="/wahlvorschlaege" onClick={onElectionProposalClicked}>Wahlvorschläge</a></MenuItem>
              {(isCurrentUserLoading ? false : hasElevatedPrivileges(currentUser?.UserRole)) &&
                <MenuItem>
                  <a href="/aenderungsvorschlaege" onClick={onChangeProposalClicked}>Änderungsvorschläge</a>
                </MenuItem>
              }
              <MenuItem><a href='/swv-info' onClick={onSWVInfoClicked}>SWV Info</a></MenuItem>
              <Box sx={{ padding: '34px 0' }} />
              <PowerSettingsNewIcon sx={{ padding: '6px 0' }} onClick={onLogoutClicked} />
              <Box sx={{ padding: '34px 0' }} />
            </NavMenu>
          </div>


          <div style={{ width: "88%", left: "12%", position: "fixed", zIndex: "10" }}>
            <AppBar position="sticky" elevation={0} sx={{ boxShadow: "0 4px 2px -2px gray", padding: "0px !important" }}>
              <Toolbar style={{ zIndex: 520 }} sx={{ padding: "0px !important", height: "100%", display: "flex", justifyContent: "flex-end" }}>
                {isMobile && <Box className={cssClass.showOnDesktop}>
                  <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={onGeneralMenuClicked}>
                    {isNavMenuOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </Box>}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div onClick={() => setIsFavoriteModalOpen(true)}>
                    <FavoriteIcon style={{ fontSize: "22px", color: "white" }} />
                  </div>
                  <IconButton
                    size="large"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={onProfileMenuClicked}
                  >
                    <Avatar
                      style={{ width: '30px', height: '30px' }}
                      src={photoURL}>
                      <PersonIcon />
                    </Avatar>
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => onProfileMenuClose()}
                  >
                    <MenuItem onClick={onFavoriteClicked}>Favoriten</MenuItem>
                    <MenuItem onClick={onHistoryClicked}>Verlauf</MenuItem>
                    <MenuItem onClick={onUserGoalClicked}>Ziele</MenuItem>
                    <MenuItem onClick={onProfileClicked}>Profil</MenuItem>
                    <MenuItem onClick={onLogoutClicked}>Abmelden</MenuItem>
                  </Menu>
                </div>
                <Box className={cssClass.hideOnDesktop}>
                  <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={onGeneralMenuClicked}>
                    {isNavMenuOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
          </div>
          <div style={{ width: "88%", marginTop: "7vh", marginLeft: "12vw", minHeight: "100%", paddingBottom: "3em" }}>
            {children}
          </div>
        </div>
      }
      <PersonPopUp
        isOpen={isPersonModalOpen}
        onClose={onPersonModalClose}
        chosenPerson={chosenPerson}
        setChosenPerson={setChosenPerson}
      />
      <CompanyPopUp
        isOpen={isCompanyModalOpen}
        onClose={onCompanyModalClose}
      />
      <FavoritePopUp
        isOpen={isFavoriteModalOpen}
        onClose={onFavoriteModalClose}
      />
      <HistoryPopUp
        isOpen={isHistoryModalOpen}
        onClose={onHistoryModalClose}
      />
      <UserGoalPopUp
        isOpen={isUserGoalModalOpen}
        onClose={onUserGoalModalClose}
      />
      <ProfilePopUp
        isOpen={isProfileModalOpen}
        onClose={onProfileModalClose}
      />
    </NavbarContext.Provider>
  );
}
