import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useGlobalStyles } from "../../../../customStyles";
import { countiesWithValues } from "../../../../constants/counties";
import { UserContext } from "../../../pages/User/UserContext";
import { useGetGeneralUserPermissionsQuery } from "../../../../redux/services/UserPermissions/UserPermissionsService";
import { useHandleInsertGeneralUserPermissions } from "../../../../redux/services/UserPermissions/UserPermissionsRequestHandlers";
import SaveButton from "../../../common-components/SaveButton/SaveButton";

interface ICountyWithValue {
    value: string
    name: string
}

export default function GeneralPermissionsCountyTable() {

    /* TODO: fetch already permitted counties from db for user's general permission, implement handle save */

    const { currentUser } = useContext(UserContext);

    const { data: generalUserPermissions } = useGetGeneralUserPermissionsQuery(currentUser ? currentUser.ID : 0);
    const { handleInsertGeneralUserPermissions, isLoading: isInsertGeneralUserPermissionsLoading } = useHandleInsertGeneralUserPermissions();

    const cssClass = useGlobalStyles();

    const handleSave = async () => {
        const data = {
            UserId: currentUser.ID,
            Counties: [...selectedCounties]
        }

        await handleInsertGeneralUserPermissions(data);
    }

    const counties: Array<ICountyWithValue> = countiesWithValues;

    const [selectedCounties, setSelectedCounties] = useState<string[]>([]);

    const handleCheckboxChange = (countyValue: string) => {
        const index = selectedCounties.indexOf(countyValue);

        if (index === -1) {
            setSelectedCounties([...selectedCounties, countyValue]);
        }
        else {
            const newArr = [...selectedCounties];
            newArr.splice(index, 1);
            setSelectedCounties(newArr);
        }
    }

    const checkboxIsChecked = (countyValue: string) => {
        return selectedCounties.includes(countyValue);
    }


    useEffect(() => {
        setSelectedCounties(generalUserPermissions ? generalUserPermissions.map(entry => entry.County) : [])
    }, [generalUserPermissions])

    return (
        <Table stickyHeader>
            <TableHead>
                <TableCell></TableCell>
                <TableCell>Bundesland</TableCell>
            </TableHead>
            <TableBody>
                {counties.map((county, index) => (
                    <TableRow key={index} onClick={() => handleCheckboxChange(county.value)}>
                        <TableCell><input className={cssClass.checkboxScaled} type="checkbox" onChange={() => handleCheckboxChange(county.value)} checked={checkboxIsChecked(county.value)} /></TableCell>
                        <TableCell>{county.name}</TableCell>
                    </TableRow>
                ))}

            </TableBody>
            <TableFooter style={{ position: 'sticky', bottom: 0, width: "100%" }}>
                <TableRow className={cssClass.defaultCursor} style={{ backgroundColor: '#f5f5f5' }} >
                    <TableCell colSpan={11}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <SaveButton
                                disabled={isInsertGeneralUserPermissionsLoading}
                                loading={isInsertGeneralUserPermissionsLoading}
                                onClick={handleSave}
                            />
                        </div>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    )
}