import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

interface NestJSError {
  error: string;
  message: string;
  statusCode: number;
}

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * @summary Util function that checks if a custom error message from the API is available
 * @param error Error from catch clause in request handler hook
 * @returns Error Messsage as a string to be displayed in snackbar or elsewhere
 */
export function RTKQueryErrorHandler(error: unknown): string | any {
  let errorMessage = 'Ein Fehler ist aufgetreten';
  console.log('RTKQUERYERROR');
  if (isFetchBaseQueryError(error)) {
    const errorData = error.data as NestJSError;
    errorMessage = errorData.message;
  }

  if (errorMessage) {
    errorMessage = errorMessage?.replace(/^[\"']|[\"']$/g, ''); //remove boundary quotes from serialized error message

    return errorMessage;
  } else {
    return error;
  }
}
