import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../basequery";
import * as endpoints from '../../../constants/endpoints';
import { IUser } from "../../../interfaces/IUser";

export const UserApi = createApi({
    reducerPath: "User",
    baseQuery,
    tagTypes: ["User", 'CurrentUser'],
    endpoints: (builder) => ({
        createUser: builder.mutation<IUser, IUser>({
            query: (data) => ({
                url: "/insertUser",
                method: "POST",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "User" }]
        }),
        getCurrentUser: builder.query<IUser, void>({
            query: () => endpoints.getCurrentUser,
            providesTags: ['CurrentUser']
        }),
        getAllUsers: builder.query<IUser[], void>({
            query: () => "/getAllUsers",
            providesTags: (result) => result ? [...result.map(({ ID }) => ({ type: "User" as const, ID })), "User"] : ["User"]
        }),
        updateUser: builder.mutation<IUser, IUser>({
            query: (data) => ({
                url: "/updateUser",
                method: "PUT",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "User", ID: arg.ID }]
        }),
        deleteUser: builder.mutation<void, number>({
            query: (id) => ({
                url: `deleteUser/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, arg) => [{ type: "User", ID: arg }]

        })
    })
})

export const {
    useCreateUserMutation,
    useGetAllUsersQuery,
    useGetCurrentUserQuery,
    useUpdateUserMutation,
    useDeleteUserMutation
} = UserApi