import React from 'react';

import FormSelect from '../FormSelect';
import { MenuItem } from '@mui/material';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import { Control } from 'react-hook-form';
import { IUser } from '../../../interfaces/IUser';

interface IFormBooleanSelect {
  name: string
  label: string
  control: Control<IUser, any>
  value: boolean
  required?: boolean
  disabled?: boolean
  defaultValue?: string
  emptyText?: string
  trueText?: string
  falseText?: string
  noDefaultValue?: boolean
}

export default function FormBooleanSelect({
  name,
  label,
  control,
  value,
  required,
  disabled,
  defaultValue,
  emptyText,
  trueText,
  falseText,
  noDefaultValue
}: IFormBooleanSelect) {
  const classes = useStyles();
  const cssGlobalClass = useGlobalStyles();

  return (
    <FormSelect
      required={required}
      label={label}
      name={name}
      control={control}
      value={value}
      defaultValue={defaultValue}
      emptyText={emptyText}
      disabled={disabled}
      noDefaultValue={noDefaultValue}
    >
      <MenuItem value={true as any}>{trueText ?? 'Ja'}</MenuItem>
      <MenuItem value={false as any}>{falseText ?? 'Nein'}</MenuItem>
    </FormSelect>
  )
};
