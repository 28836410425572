import React from 'react';
import { useEffect, useContext, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { FormControl, Button, Grid, Box, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import ModalWrapper from '../../common-components/ModalWrapper';
import CountySelect from '../../common-components/CountySelect';
import BoxCenter from '../../common-components/BoxCenter';
import FormTextField from '../../common-components/FormTextField';
import FormBooleanSelect from '../../common-components/FormBooleanSelect';
import { defaultUser, UserContext } from '../../pages/User/UserContext';
import { useStyles } from './styles';
import UserRoleSelect from '../../common-components/UserRoleSelect/UserRoleSelect';
import { IUser } from '../../../interfaces/IUser';
import SaveButton from '../../common-components/SaveButton/SaveButton';

interface ICreateUserPopUp {
    isOpen: boolean
    onClose: () => void
    onSaveClicked: (user: IUser) => void
    isCreateUserLoading: boolean
}

export default function CreateUserPopUp({
    isOpen,
    onClose,
    onSaveClicked,
    isCreateUserLoading
}: ICreateUserPopUp) {
    const classes = useStyles();
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);

    const { currentUser } = useContext(UserContext);

    const { control, reset } = useForm<IUser>({
        defaultValues: { ...defaultUser }
    });

    const values = useWatch({ control }) as NonNullable<IUser>;

    const disabled =
        emailError ||
        mobileError ||
        !values?.Username ||
        !values?.Email ||
        !values?.Mobile ||
        !values?.Firstname ||
        !values?.Lastname

    useEffect(() => {
        if (isOpen) {
            reset(currentUser);
        } else {
            reset(defaultUser);
        }
    }, [isOpen]);

    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose} title="Benutzer erstellen">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl>
                    <FormTextField
                        name="Username"
                        label="Benutzername"
                        control={control}
                        required
                    />
                    <FormTextField
                        name="Email"
                        label="Email"
                        control={control}
                        required
                        type="email"
                        func={setEmailError}
                    />
                    <FormTextField
                        name="Mobile"
                        label="Mobil"
                        control={control}
                        required
                        type="mobile"
                        func={setMobileError}
                    />
                    <FormTextField
                        name="Firstname"
                        label="Vorname"
                        control={control}
                        required
                    />
                    <FormTextField
                        name="Lastname"
                        label="Nachname"
                        control={control}
                        required
                    />
                    <FormBooleanSelect
                        label="Admin"
                        name="IsAdmin"
                        control={control}
                        value={values?.IsAdmin!!}
                        required
                    />
                    <CountySelect
                        name="County"
                        control={control}
                        value={values?.County || ''}
                    />
                    <FormBooleanSelect
                        label="Aktiv"
                        name="IsActive"
                        control={control}
                        value={values?.IsActive!!}
                        required
                    />
                    <FormBooleanSelect
                        label="Sendeeinschränkung"
                        name="SendingRestriction"
                        control={control}
                        value={values?.SendingRestriction!!}
                        required
                    />
                    <UserRoleSelect
                        control={control}
                        value={values?.UserRole || ''}
                        fullWidth

                    />
                </FormControl>
                <BoxCenter>
                    {/*                     <Button
                        onClick={() => onSaveClicked(values)}
                        disabled={disabled}
                    >
                        Speichern
                    </Button> */}
                    <SaveButton
                        disabled={disabled}
                        loading={isCreateUserLoading}
                        onClick={() => onSaveClicked(values)}
                    />
                </BoxCenter>
            </LocalizationProvider>
        </ModalWrapper>
    );
}