export const Messages = {
  Success: 'Aktion erfolgreich',
  Error: 'Aktion nicht erfolgreich',
  ErrorInsert: 'Einfügen nicht erfolgreich',
  ErrorDelete: 'Löschen nicht erfolgreich',
  ErrorUpdate: 'Aktualisieren nicht erfolgreich',
  ErrorUpload: 'Datei / Foto raufladen nicht erfolgreich',
  ErrorDownload: 'Datei / Foto runterladen nicht erfolgreich',
  ErrorDownloadNotFound: 'Datei / Foto existiert nicht',
  ErrorParameters: 'Bitte Eingaben überprüfen',
  ErrorPasswords: 'Passwörter stimmen nicht überein',
  ErrorPassword: 'Passwort mind. 6stellig, Groß/Klein/Zahlen',
  ErrorContactPerson: 'Person ist bereits Ansprechperson',
  ErrorMasterContactPerson: 'Person ist primäre Ansprechperson',
  ErrorAddress: 'Person hat keine Addresse',
  ErrorFetch: 'Daten konnten nicht vom Server abgerufen werden',
  ErrorUserInactive: 'Benutzer ist nicht aktiv',
  NotFound: 'Datensatz nicht gefunden',
  WarningUnsafed: 'Nicht Gespeichert!',
  Inserted: 'Datensatz eingefügt',
  Updated: 'Datensatz aktualisiert',
  Deleted: 'Datensatz gelöscht',
  ErrorTitle: 'Bitte geben Sie einen Titel ein',
  ErrorCounty: 'Bitte wählen Sie ein Bundesland',
  ChangeProposalCreated: 'Änderungsvorschlag erstellt',
  Invited: 'Benutzer erfolgreich eingeladen',
  MemberInsert: 'Mitglied wurde hinzugefügt',
  MemberUpdate: 'Mitglied wurde aktualisiert',
  MemberNumberValidation: 'Die eingegebene Nummer existiert bereits!',

  NoPersonFound: 'Keine Person gefunden',
  FileMissing: 'Datei fehlt',
  PhotoMissing: 'Foto fehlt',
  EmailMissing: 'Email fehlt',
  InvalidEmail: 'Email ungültig',
  EmailWithoutCheckbox: 'Email ohne checkbox',
  UsernameInUse: 'Username bereits vergeben',
  BusinessSubcategoryModalCantBeOpened: 'Sie müssen zuerst Sparte und Bundesland auswählen',
  WKOInUse: 'Unternehmen mit dieser WKO-Nummer existiert bereits',
  WKONotInUse: 'WKO-Nummer nicht verwendet',
  EmptySearchResult: 'Keine Firmen gefunden',
  NavigateToCompanyPage: 'In die Firmenseite wechseln',
  FeatureNotAvailable: 'Funktion nicht verfügbar',
  ErrorSearchMissingSelection: 'Keine Option ausgewählt',

  XXX: '',

  MailtextForm: `Sehr geehrt[Anrede],
anbei übermitteln wir Ihnen [Formular]. Bitte prüfen Sie die angegebenen Informationen auf Korrektheit. 
  
Wir möchten uns im Namen des gesamten SWV bei Ihnen für Ihre Unterstützung bedanken.
  
Für Rückfragen oder weitere Informationen stehen wir Ihnen gerne zur Verfügung.
  
Mit freundlichen Grüßen,
Ihr SWV
  
Ihre Ansprechperson:
[Name]
[Tel]
[Email]`
};
