import React, { useContext } from 'react';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import { ContactPersonContext } from '../../PersonContext';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';
import { swvTheme } from '../../../../../swvTheme';
import { chooseContactPersonPosition, getDate } from '../../../../../helpers/database';
import BoxCenter from '../../../../common-components/BoxCenter';

export const ContactPersonList = () => {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const {
    chosenPerson,
    chosenContactPerson,
    handleNewContactPersonClick,
    setChosenContactPerson,
    companyData,
  } = useContext(ContactPersonContext);

  const disabled = chosenPerson?.ContactPersons?.some(contactPerson => contactPerson.FK_Company === companyData?.ID);

  const onRowClicked = async (row) => {
    await setChosenContactPerson(row);
  };

  return (
    <>
      <Grid xs={12} mt={3} mb={3}>
        <Box className={classes.box}>
          <Paper className={classes.paper}>
            <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}  py={4}>Kontaktpersonen</Typography>

            <Grid container spacing={2}>
              {chosenPerson?.ContactPersons?.map((row) => (
                <Grid item xs={12} md={6} key={row.ID}>
                  <Paper
                    className={classes.contactPersonPaper}
                    style={{
                      border: chosenContactPerson?.ID === row.ID ? '2px solid #F50000' : '1px solid #C7C7C7',
                    }}
                    onClick={() => onRowClicked(row)}
                  >
                    <Typography variant="h6" className={classes.contactPersonName}>
                      {row.Slave?.Name}
                    </Typography>
                    <Table className={classes.table}>
                      <TableBody className={cssClass.customTableBody}>
                        <TableRow>
                          <TableCell className={classes.tableCellBold}>Firma:</TableCell>
                          <TableCell>{row.Slave?.Name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableCellBold}>Position:</TableCell>
                          <TableCell>{chooseContactPersonPosition(row.Position)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableCellBold}>Anfang:</TableCell>
                          <TableCell>{getDate(row.Start)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableCellBold}>Ende:</TableCell>
                          <TableCell>{getDate(row.End)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              ))}
            </Grid>

            <Box className={classes.actionsBox}>
              {companyData?.ID && !disabled && (
                <Box>
                  <AddIcon
                    className={classes.addIcon}
                    onClick={() => handleNewContactPersonClick()}
                  />
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};
