import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { CookiesProvider } from 'react-cookie';

import { Route, Routes } from 'react-router-dom';
import { HistoryRouter as BrowserRouter } from 'redux-first-history/rr6';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';

import PrivateRoutes from './router/PrivateRoutes';
import { Provider, useDispatch } from 'react-redux';
import { history, store } from './redux/store';

import Login from './components/pages/Login';
import SecondFactorLogin from './components/pages/SecondFactorLogin';
import PasswordForgotten from './components/pages/PasswordForgotten';
import Dashboard from './components/pages/Dashboard';
import PasswordSet from './components/pages/PasswordSet';
import Company from './components/pages/Company/Company';
import { swvTheme } from './swvTheme';

import AdminGoal from './components/pages/AdminGoal';
import AdminCheck from './components/pages/AdminCheck';
import User from './components/pages/User/User';
import AdminMunicipality from './components/pages/AdminMunicipality';
import ExportPerson from './components/pages/ExportPerson';
import AdminOrganisation from './components/pages/AdminOrganisation';
import AdminHonor from './components/pages/AdminHonor';
import AdvancedSearch from './components/pages/AdvancedSearch';
import { SWVInfo } from './components/pages/SWVInfo/SWVInfo';
import { FormOverview } from './components/pages/FormOverview/FormOverview';
import { setUserData } from './redux/slices/appSlice';
import AdminElectionProposal from './components/pages/ElectionProposal/AdminElectionProposal';
import AdminChangeProposalOverview from './components/pages/AdminChangeProposalOverview';
import Navbar from './components/pages/Navbar';
import { FormSending } from './components/pages/FormSending/FormSending';
import CustomTreeView from './components/pages/TreeView/TreeView';

function DismissAction({ id }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <Button
      sx={{
        color: 'white',
        minWidth: '0',
        width: '10px',
        backgroundColor: 'transparent',
        margin: '0 5px 0 0',
      }}
      onClick={() => closeSnackbar(id)}
      endIcon={<HighlightOffIcon />}
    />
  );
}

export default function App() {

  const dispatch = useDispatch();

  //Wenn jemand die App aufruft und bereits Userdaten im localStorage gespeichert sind, sollen diese aus dem localStorage in den Redux Slice geladen werden
  useEffect(() => {
    // Benutzerdaten aus dem localStorage abrufen
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      // Benutzerdaten im Redux Store aktualisieren
      dispatch(setUserData(userData));
    }
  }, [dispatch]); // Stelle sicher, dass useEffect nur einmal beim Laden der App ausgeführt wird


  return (
    <CookiesProvider>
      <ThemeProvider theme={swvTheme}>
        {/* <React.StrictMode> */}
        <SnackbarProvider
          maxSnack={3}
          action={(key) => <DismissAction id={key} />}
          hideIconVariant
        >
          <BrowserRouter history={history}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/zweifaktor" element={<SecondFactorLogin />} />
              <Route path="/passwortvergessen" element={<PasswordForgotten />} />
              <Route path="/passwortvergeben/:token" element={<PasswordSet />} />
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/firma/:id" element={<Company />} />
                <Route path="/kontrolle" element={<AdminCheck />} />
                <Route path="/benutzer" element={<User />} />
                <Route path="/gemeinden" element={<AdminMunicipality />} />
                <Route path="/ziele" element={<AdminGoal />} />
                <Route path="/ehrungen" element={<AdminHonor />} />
                <Route path="/organisationen" element={<AdminOrganisation />} />
                <Route path="/spezialsuche" element={<AdvancedSearch />} />
                <Route path="/swv-info" element={<SWVInfo />} />
                <Route path="/formulare" element={<FormOverview />} />
                <Route path="/formularversand" element={<FormSending />} />
                <Route path="/wahlvorschlaege" element={<AdminElectionProposal />} />
                <Route path="/aenderungsvorschlaege" element={<AdminChangeProposalOverview />} />
                <Route path="/organisationssuche" element={<CustomTreeView />} />
                <Route path="/Exportieren" element={<ExportPerson />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
        {/* </React.StrictMode> */}
      </ThemeProvider>
    </CookiesProvider>
  );
}
