import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../basequery";
import * as endpoints from "../../constants/endpoints";

export const PanelAPI = createApi({
    reducerPath: 'Panel',
    baseQuery,
    tagTypes: ["panels"],
    endpoints: (build) => ({
        getList: build.query({
            query: (organizationID) => ({
                url: endpoints.getPanelList,
                body: {
                    FK_Organisation: organizationID,
                },
                method: 'POST',
            }),
            providesTags: () => ['panels']
        })
    })
});
